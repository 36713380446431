import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import React, { useEffect, useState } from 'react';

// Đăng ký các thành phần của biểu đồ
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
            text: 'Tổng doanh thu theo 30 ngày gần nhất',
        },
    },
};

export function MyChartMonthTotal({ year, month }) {
    const [data, setData] = useState({
        labels: [], // Để trống ban đầu, sẽ cập nhật sau khi fetch dữ liệu
        datasets: [
            {
                label: 'Total Revenue',
                data: [],
                backgroundColor: 'rgba(75, 192, 192, 0.6)', // Màu đẹp hơn
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    });

    const fetchTotalRevenue = async (year, month) => {
        try {
            const response = await fetch(`https://api.pethaven.website/admin/revenue/total/revenuemonth/${year}/${month}`);
            if (!response.ok) throw new Error('Network response was not ok');
            const totalData = await response.json();

            // Tạo mảng nhãn và dữ liệu từ daily_revenue_by_day_in_month
            const labels = Object.keys(totalData.daily_revenue_by_day_in_month);
            const totalValues = Object.values(totalData.daily_revenue_by_day_in_month).map(Number);

            setData((prevData) => ({
                ...prevData,
                labels, // Cập nhật nhãn cho biểu đồ
                datasets: [
                    {
                        ...prevData.datasets[0],
                        data: totalValues, // Cập nhật dữ liệu tổng doanh thu
                    },
                ],
            }));
        } catch (error) {
            console.error('Đã xảy ra lỗi:', error);
        }
    };

    // useEffect sẽ gọi lại khi năm hoặc tháng thay đổi
    useEffect(() => {
        fetchTotalRevenue(year, month);
    }, [year, month]); // Khi year hoặc month thay đổi, sẽ gọi lại API

    return <Bar options={options} data={data} />;
}
