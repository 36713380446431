import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../context/CartContext';

const DescriptionTabs = ({ relatedProducts = [] }) => {
    const { addToCart } = useContext(CartContext);
  return (
    <div className="product-description mt-4">
      <h4 className="content-service">Mô tả</h4>
      <p className="custom-description-cart-item">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed molestie sapien, 
        aliquam et facilisis arcu...
      </p>

     
      {relatedProducts.length > 0 && (
        <div className="related-products mt-5">
          <h4 className="content-service">Sản phẩm liên quan</h4>
          <div className="row">
            {relatedProducts.map((product) => (
              <div className="col-md-3" key={product.id}>
                <div className="product-card">
                  <img src={`https://api.pethaven.website/images/products/${product.image}`} alt={product.name} />
                  <div className="product-title">
                    <Link to={`/product/${product.id}`}>{product.name}</Link>
                  </div>
                  <div className="product-sale-price">
                    {product.price.toLocaleString()}đ
                  </div>
                  <div className="product-rating justify-content-center">
                    <ul className="star-list">
                      <li><i className="fa-solid fa-star"></i></li>
                      <li><i className="fa-solid fa-star"></i></li>
                      <li><i className="fa-solid fa-star"></i></li>
                      <li><i className="fa-solid fa-star-half-alt"></i></li>
                      <li><i className="fa-regular fa-star"></i></li>
                    </ul>
                    <span>(3.5)</span>
                  </div>
                  <div className="product-buttons">
                    <Link to={`/product/${product.id}`} className="btn-add-cart-product" onClick={() => addToCart(product)}>
                      Thêm vào giỏ hàng
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DescriptionTabs;
