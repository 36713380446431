import React, { useState, useEffect } from 'react';

const ReviewTab = ({ productId }) => {
  const [reviews, setReviews] = useState([]);
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Lấy danh sách đánh giá mà không cần token
    fetch(`https://api.pethaven.website/api/products/${productId}/reviews`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Response:', response);
        if (!response.ok) throw new Error('Lỗi khi tải đánh giá');
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setReviews(data);
        } else {
          setError('Lỗi khi tải đánh giá');
        }
      })
      .catch((err) => setError(err.message || 'Lỗi kết nối tới API'));
  }, [productId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const user = JSON.parse(localStorage.getItem('user'));  // Lấy dữ liệu user từ localStorage và parse thành object
    const token = user?.token;  // Kiểm tra token nếu có
    
    // Kiểm tra user_id
    const userId = user?.id;
    if (!userId) {
      setError('Bạn cần đăng nhập để thêm đánh giá.');
      return;
    }
    
    // Kiểm tra comment và rating không rỗng
    if (!comment || rating === 0) {
      setError('Bạn cần nhập đủ bình luận và đánh giá.');
      return;
    }
    
    // Gửi yêu cầu thêm đánh giá
    fetch(`https://api.pethaven.website/api/products/${productId}/reviews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ comment, rating, user_id: userId }),  // Thêm user_id vào dữ liệu gửi đi
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            console.error('Error details:', errorData);
            throw new Error(errorData.message || 'Lỗi khi thêm đánh giá');
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setReviews((prev) => [
            ...prev,
            { 
              Comment: comment, 
              Rating: rating, 
              created_at: new Date().toISOString(), 
              user: { name: user?.name || 'Người dùng' } 
            }
          ]);
          
          setComment('');
          setRating(0);
        } else {
          setError(data.message || 'Lỗi khi thêm đánh giá');
        }
      })
      .catch((err) => {
        console.error('Error:', err);
        setError(err.message || 'Lỗi kết nối tới API');
      });
  };
  

  return (
    <>
      <div className="reviews mt-4">
        <h4 className="content-service">Bình luận</h4>
        {error && <p className="text-danger">{error}</p>}
        {reviews.map((review, index) => (
  <div className="review" key={index}>
    <h5 className="user-review">{review.user?.name || 'Người dùng'}</h5> {/* Hiển thị tên người dùng nếu có */}
    <p className="custom-description-cart-item">
      <span className="text-muted">{new Date(review.created_at).toLocaleDateString()}</span>
    </p>
    <p className="custom-description-cart-item">{review.Comment}</p>
<p className="custom-description-cart-item"> {Array.from({ length: review.Rating }, (_, i) => (
                      <span key={i}>★</span>
                    ))}</p>

  </div>
))}


        {/* Hiển thị form đánh giá mà không cần kiểm tra đăng nhập */}
        <div className="review-form mt-4">
          <h4 className="content-service">Thêm bình luận</h4>
          <form onSubmit={handleSubmit}>
            <textarea
              name="review"
              placeholder="Đánh giá của bạn..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
            ></textarea>

            <label htmlFor="rating">Đánh giá (1-5):</label>
            <input
              type="number"
              id="rating"
              name="rating"
              min="1"
              max="5"
              value={rating}
              onChange={(e) => setRating(Number(e.target.value))}
              required
            />

            <button className="add-review mt-2" type="submit">
              Gửi đánh giá
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ReviewTab;
