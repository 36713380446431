import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
    return (
      <>
        <footer className="footer pt-5 pb-4">
          <div className="container text-center text-md-left">
            <div className="row">
             
              <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mt-3">
                <h5 className="mb-4 font-weight-bold">
                  <img
                    src="../img/Red & Orange Simple Petshop Logo.png"
                    alt="Pet Planet Logo"
                    style={{ height: "40px" }}
                  />
                  PET HAVEN MEDIA
                </h5>
                <p>
                  Họ tiêu diệt kẻ thù không bằng những mũi tên dễ dàng. Một cách
                  khôn ngoan, bây giờ chúng ta sẽ phải chịu đau đớn.
                </p>
                <div>
                  <Link href="#">
                    <i className="fab fa-twitter fa-lg text-white mr-3"></i>
                  </Link>
                  <Link href="#">
                    <i className="fab fa-facebook fa-lg text-white mr-3"></i>
                  </Link>
                  <Link href="#">
                    <i className="fab fa-pinterest fa-lg text-white mr-3"></i>
                  </Link>
                  <Link href="#">
                    <i className="fab fa-instagram fa-lg text-white"></i>
                  </Link>
                </div>
              </div>
  
            
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                <h5 className="text-uppercase mb-4 font-weight-bold">Danh mục</h5>
                <p>
                  <Link to="#" className="text-white" style={{ textDecoration: "none" }}>
                    Giới thiệu
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-white" style={{ textDecoration: "none" }}>
                    Dịch vụ
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-white" style={{ textDecoration: "none" }}>
                    Sản phẩm
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-white" style={{ textDecoration: "none" }}>
                    Tin tức
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-white" style={{ textDecoration: "none" }}>
                    Liên hệ
                  </Link>
                </p>
              </div>
  
              {/* Cột phòng trưng bày */}
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <h5 className="text-uppercase mb-4 font-weight-bold">Phòng trưng bày</h5>
                <div className="row">
                  <div className="col-4 mb-3">
                    <img src="../img/pet1.jpg" className="img-fluid" alt="Pet 1" />
                  </div>
                  <div className="col-4 mb-3">
                    <img src="../img/pet2.jpg" className="img-fluid" alt="Pet 2" />
                  </div>
                  <div className="col-4 mb-3">
                    <img src="../img/pet3.jpg" className="img-fluid" alt="Pet 3" />
                  </div>
                  <div className="col-4 mb-3">
                    <img src="../img/pet4.jpg" className="img-fluid" alt="Pet 4" />
                  </div>
                  <div className="col-4 mb-3">
                    <img src="../img/pet5.jpg" className="img-fluid" alt="Pet 5" />
                  </div>
                </div>
              </div>
  
   
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <h5 className="text-uppercase mb-4 font-weight-bold">Liên hệ</h5>
                <p>
                  <i className="fas fa-map-marker-alt mr-3"></i> 1073/23 Cách Mạng Tháng 8, P.7, Q.Tân Bình, TP.HCM
                </p>
                <p>
                  <i className="fas fa-phone mr-3"></i> (+84) 0313728397
                </p>
                <p>
                  <i className="fas fa-clock mr-3"></i> Thứ 2 - Thứ 6: 09:00 - 18:00
                </p>
                <p>
                  <i className="fas fa-envelope mr-3"></i> info@themona.global
                </p>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
  
  export default Footer;