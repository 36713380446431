import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

const OderHistoryDn = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const toggleDropdown = () => {
        setDropdownVisible((prev) => !prev);
    };

    // Lấy thông tin user từ localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user ? user.id : null;
    const userName = user ? user.name : 'Người dùng'; // Nếu không có tên, mặc định là 'Người dùng'
    const userImage = user ? user.img : 'https://api.pethaven.website/images/user/default.jpg'; // Nếu không có ảnh, dùng ảnh mặc định

    // Fetch orders when component mounts
    useEffect(() => {
        if (!userId) {
            setError('Vui lòng đăng nhập để xem đơn hàng của bạn.');
            setLoading(false);
            return; // Dừng lại nếu không có userId
        }

        axios.get(`https://api.pethaven.website/api/orders/success/${userId}`)
            .then(response => {
                setOrders(response.data);
                setLoading(false);
            })
            .catch(err => {
                setError('Lỗi khi tải dữ liệu');
                setLoading(false);
            });
    }, [userId]);

    const handleReturnOrder = (orderId) => {
        // Gọi API để hoàn trả hàng và thay đổi trạng thái đơn hàng thành 'prepare'
        axios.post(`https://api.pethaven.website/api/orders/return/${orderId}`)
            .then(response => {
                // Cập nhật lại danh sách đơn hàng
                setOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
                alert('Đơn hàng sẽ được hoàn trả và chờ nhận được xử lý');
            })
            .catch(err => {
                alert('Lỗi khi hoàn trả đơn hàng.');
            });
    };

    // Tính số ngày từ khi nhận hàng
    const canReturnOrder = (receivedDate) => {
        const currentDate = new Date();
        const receivedDateObj = new Date(receivedDate);
        const diffTime = Math.abs(currentDate - receivedDateObj);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Số ngày chênh lệch
        return diffDays <= 7;
    };

    if (loading) {
        return (
            <div className="loading-container">
                <svg viewBox="25 25 50 50" className="spinner">
                    <circle r="20" cy="50" cx="50"></circle>
                </svg>
            </div>
        );
    }

    return (
        <div className="container center-personel">
            <div className="row profile-container">
                {/* Sidebar */}
                <div className="profile-sidebar">
                    <img
                        id="profile-image"
                        src={`https://api.pethaven.website/images/user/${userImage}`}
                        alt="Profile Picture"
                        className="profile-picture"
                    />
                    <div className="user-info-personal">
                        <h4>{userName}</h4>
                        <p>Đang hoạt động</p>
                        <Link to="/BookingHistory">Lịch sử đặt lịch</Link>
                        <a href="#" onClick={toggleDropdown}>
                            Đơn hàng của tôi
                        </a>
                        {dropdownVisible && (
                            <div id="order-status-dropdown">
                                <ul>
                                <li><Link to="/OrderHistory">Chờ xác nhận</Link></li>
                  <li><Link to="/OderHistoryXn">Đã xác nhận</Link></li>
                  <li><Link to="/OderHistoryShip">Giao hàng</Link></li>
                  <li><Link to="/OderHistoryDn">Hoàn thành</Link></li>
                  <li><Link to="/OderHistoryHuy">Đã hủy</Link></li>
                  <li><Link to="/OderHistoryReturn">Hoàn trả</Link></li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>

                {/* Order History Table */}
                <div className="profile-info purchase-history-container">
                    <h4>Đã Nhận</h4>
                    <p>Tất cả đơn hàng đã nhận</p>
                    <table className="order-history-table">
                        <thead>
                            <tr>
                                <th>Mã Đơn Hàng</th>
                                <th>Ngày Mua</th>
                                <th>Tổng Tiền</th>
                                <th>Trạng Thái</th>
                                <th>Hành Động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.length > 0 ? (
                                orders.map(order => (
                                    <tr key={order.id}>
                                        <td>{`#${order.id}`}</td>
                                        <td>{moment(order.created_at).format('DD-MM-YYYY HH:mm')}</td> {/* Định dạng ngày giờ */}
                                        <td>{order.total_money} VNĐ</td>
                                        <td>{order.status}</td>
                                        <td>
                                          
                                            {canReturnOrder(order.created_at) && (
                                                <button
                                                   className="btn-view-order"
                                                    onClick={() => handleReturnOrder(order.id)}
                                                >
                                                    Hoàn trả hàng
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">Không có đơn hàng nào đã nhận.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default OderHistoryDn;
