import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin';
const ThongKeSanPhamBan = () => {
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.pethaven.website/admin/report/product');
        setProductData(response.data.top_selling_products || []);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-10 ms-sm-auto px-md-4">
            <div className="header">
              <h4>Thống kê sản phẩm bán chạy</h4>
              <UserInfo />
            </div>
            <div className="container mt-4">
              <div className="card">
                <div className="card-header">Thống kê sản phẩm bán chạy</div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className="table-light">
                        <tr>
                          <th>STT</th>
                          <th>Tên sản phẩm</th>
                          <th>Số lượng đã bán</th>
                          <th>Doanh thu (VND)</th>

                        </tr>
                      </thead>
                      <tbody>
                        {productData.map((product, index) => (
                          <tr key={product.product_id}>
                            <td>{index + 1}</td>
                            <td>{product.name}</td>
                            <td>{product.total_quantity}</td>
                            <td>{product.total_revenue.toLocaleString()} VND</td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThongKeSanPhamBan;
