import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Kiểm tra localStorage khi UserProvider được khởi tạo
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser); // Đặt user vào state nếu tồn tại trong localStorage
    }
  }, []);

  useEffect(() => {
    // Mỗi khi `user` thay đổi, lưu lại vào localStorage
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
      console.log("User saved to localStorage:", user); // Thêm log để kiểm tra lưu vào localStorage
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
