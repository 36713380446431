import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import React, { useEffect, useState } from 'react';

// Đăng ký các thành phần cần thiết cho biểu đồ
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false, // Ẩn chú thích vì chỉ có một cột
        },
        title: {
            display: true,
            text: 'Tổng doanh thu trong 7 ngày gần nhất',
        },
    },
};

// Hàm biểu đồ
export function MyChartDaytotal() {
    const [data, setData] = useState({
        labels: [], // Mảng rỗng cho nhãn ngày
        datasets: [
            {
                label: 'Total Revenue',
                data: [], // Dữ liệu tổng doanh thu
                backgroundColor: 'rgba(255, 206, 86, 0.7)', // Màu xanh đẹp mắt
                borderColor: 'rgba(255, 206, 86, 0.7)',
                borderWidth: 1,
            },
        ],
    });

    // Hàm để fetch dữ liệu tổng doanh thu trong 7 ngày
    const fetchTotalRevenue = async () => {
        try {
            const response = await fetch('https://api.pethaven.website/admin/revenue/total/revenue-last-7-days');
            if (!response.ok) throw new Error('Network response was not ok');
            const totalData = await response.json();

            // Xử lý dữ liệu nhãn và giá trị cho biểu đồ
            const labels = Object.keys(totalData.daily_revenue_last_7_days); // Sử dụng đúng tên trường
            const totalValues = Object.values(totalData.daily_revenue_last_7_days).map(Number); // Chuyển giá trị thành số

            // Cập nhật dữ liệu vào state
            setData({
                labels: labels,
                datasets: [
                    {
                        label: 'Total Revenue',
                        data: totalValues,
                        backgroundColor: 'rgba(255, 206, 86, 0.7)', // Màu xanh đẹp mắt
                    },
                ],
            });
        } catch (error) {
            console.error('Đã xảy ra lỗi:', error);
        }
    };

    useEffect(() => {
        fetchTotalRevenue(); // Gọi hàm để lấy dữ liệu
    }, []);

    return <Bar options={options} data={data} />;
}
