import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchUsers } from '../../services/Admin/UserService';
import Sidebar from '../../components/SliderBar';
import UserInfo from '../../components/UserInfoAdmin';

const UserAdmin = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const getUsers = async () => {
    setLoading(true);
    try {
      const { data, last_page } = await fetchUsers(search, page, perPage);
      setUsers(data);
      setTotalPages(last_page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [search, page, perPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    getUsers();
  };

  const handleStatusToggle = async (user) => {
    const newStatus = user.is_action === 0 ? 1 : 0;
    const confirmationMessage = newStatus === 1
      ? 'Bạn có chắc chắn muốn khóa tài khoản này không?'
      : 'Bạn có chắc chắn muốn mở khóa tài khoản này không?';

    if (window.confirm(confirmationMessage)) {
      try {
        const response = await fetch(`https://api.pethaven.website/admin/user/update/${user.id}`, {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ is_action: newStatus })
        });

        const result = await response.json();
        if (response.ok) {
          alert(result.message);
          getUsers(); // Refresh list after update
        } else {
          alert('Đã xảy ra lỗi: ' + result.message);
        }
      } catch (error) {
        console.error('Lỗi kết nối API:', error);
        alert('Lỗi kết nối API');
      }
    }
  };

  if (loading) 
    <div className="loading-container">
  <svg viewBox="25 25 50 50" className="spinner">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
</div>;
  if (users.length === 0) return <div>Không có khách hàng nào.</div>;

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="content col-10">
        <div className="header">
          <div className="title">
            <h4 className="main-title-adminindex">Quản lý khách hàng</h4>
          </div>
          <UserInfo />
        </div>
        <div className="search-bar-container">
          <div className="search-bar">
            <input
              className="form-control"
              placeholder="Tìm khách hàng"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className="btn btn-dark" onClick={handleSearch}>
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div className="mt-4">
          <div className="card">
            <div className="card-header font-weight-bold">
              <strong>Khách hàng</strong>
            </div>
            <div className="card-body">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Tên</th>
                    <th>Email</th>
                    <th>SDT</th>
                    <th>Địa chỉ</th>
                    <th>Trạng thái</th>
                    <th className="text-center">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={user.id}>
                      <td>{(page - 1) * perPage + index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>{user.address}</td>
                      <td>{user.is_action === 0 ? 'Hoạt động' : 'Ngừng hoạt động'}</td>
                      <td className="text-center">
                        <Link to={`/EditUser?id=${user.id}`}>
                          <i className="fas fa-edit text-success" />
                        </Link>
                        <button
                          onClick={() => handleStatusToggle(user)}
                          style={{
                            backgroundColor: '#ff9800', /* Màu cam */
                            color: '#fff',              /* Màu chữ trắng */
                            border: 'none',
                            marginLeft: '5px',           /* Xóa viền */
                            padding: '3px 6px',        /* Padding cho nút */
                            borderRadius: '1px',        /* Bo góc */
                            cursor: 'pointer'           /* Thay đổi con trỏ khi hover */
                          }}
                          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#e68900'} /* Màu khi hover */
                          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#ff9800'}  /* Trở lại màu ban đầu */
                        >
                          {user.is_action === 0 ? (
                            <i className="fa fa-lock" aria-hidden="true"></i> // Icon khóa
                          ) : (
                            <i className="fa fa-unlock" aria-hidden="true"></i> // Icon mở khóa
                          )}
                        </button>


                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                      Previous
                    </button>
                  </li>
                  <li className="page-item">
                    <span className="page-link">Page {page} of {totalPages}</span>
                  </li>
                  <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAdmin;
