import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin'; // Import UserInfo component

const AddCategoryNews = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [categoryData, setCategoryData] = useState({
    name: '',
    description: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Lấy giá trị của query string
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const id = getQueryParam('id'); // Lấy giá trị 'id' từ query string

  // Hàm lấy dữ liệu thể loại tin tức để sửa nếu có id
  useEffect(() => {
    const fetchCategoryData = async () => {
      if (id) {
        try {
          const response = await fetch(`https://api.pethaven.website/admin/categoryNew/${id}`);
          const result = await response.json();
          if (response.ok) {
            setCategoryData(result); // Gán dữ liệu thể loại tin tức
          } else {
            alert('Lỗi khi lấy dữ liệu thể loại tin tức: ' + result.message);
          }
        } catch (error) {
          console.error('Lỗi kết nối API:', error);
        }
      }
    };
    fetchCategoryData();
  }, [id]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setCategoryData({
      ...categoryData,
      [id]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(`https://api.pethaven.website/admin/categoryNew/${id ? `update/${id}` : 'add'}`, {
        method: id ? 'PUT' : 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(categoryData)
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        navigate('/CategoryNewsAdmin'); // Chuyển hướng sau khi thêm/sửa thể loại tin tức thành công
      } else {
        alert('Đã xảy ra lỗi: ' + result.message);
      }
    } catch (error) {
      console.error('Lỗi kết nối API:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="d-flex">
        <Sidebar />

        <div className="content flex-grow-1">
          <div className="header">
            <div className="text-left">
              <h4 className="main-title-adminindex">Thêm Thể Loại Tin Tức</h4>
            </div>
            <UserInfo />
          </div>

          <div className="container mt-4">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Thể Loại Tin Tức</label>
                <input
                  type="text"
                  className="form-control form-addsp"
                  id="name"
                  placeholder="Nhập thể loại tin tức"
                  value={categoryData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="description" className="form-label">Mô Tả</label>
                <textarea
                  className="form-control form-addsp"
                  id="description"
                  rows={3}
                  placeholder="Nhập mô tả"
                  value={categoryData.description}
                  onChange={handleInputChange}
                />
              </div>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Đang xử lý...' : (id ? 'Cập Nhật Thể Loại' : 'Thêm Thể Loại')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategoryNews;
