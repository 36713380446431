import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import React, { useEffect, useState } from 'react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Doanh thu dịch vụ và đơn hàng theo năm',
    },
  },
};

export function MyChart({ year }) {
  const [data, setData] = useState({
    labels: [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ],
    datasets: [
      {
        label: 'Order Revenue',
        data: Array(12).fill(0),
        backgroundColor: 'red',
      },
      {
        label: 'Service Revenue',
        data: Array(12).fill(0),
        backgroundColor: 'blue',
      },
    ],
  });

  const fetchOrderRevenue = async (year) => {
    try {
      const response = await fetch(`https://api.pethaven.website/admin/revenue/order/year/${year}`);
      if (!response.ok) throw new Error('Network response was not ok');
      const revenueData = await response.json();

      setData((prevData) => ({
        ...prevData,
        datasets: [
          {
            ...prevData.datasets[0],
            data: Object.values(revenueData.monthly_revenue),
          },
          prevData.datasets[1],
        ],
      }));
    } catch (error) {
      console.error('Error fetching order revenue:', error);
    }
  };

  const fetchServiceRevenue = async (year) => {
    try {
      const response = await fetch(`https://api.pethaven.website/admin/revenue/service/year/${year}`);
      if (!response.ok) throw new Error('Network response was not ok');
      const revenueData = await response.json();

      setData((prevData) => ({
        ...prevData,
        datasets: [
          prevData.datasets[0],
          {
            ...prevData.datasets[1],
            data: Object.values(revenueData.monthly_revenue),
          },
        ],
      }));
    } catch (error) {
      console.error('Error fetching service revenue:', error);
    }
  };

  useEffect(() => {
    fetchOrderRevenue(year);
    fetchServiceRevenue(year);
  }, [year]); // Chạy lại khi year thay đổi

  return <Bar options={options} data={data} />;
}
