import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPassword, login } from '../../services/AthSevices';
import { Link } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'; // Import GoogleOAuthProvider and GoogleLogin

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '', remember: false });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { setUser, user } = useUser();

  useEffect(() => {
    if (user) {
      navigate(user.role === 'admin' ? '/Dashboard' : '/');
    }
  }, [user, navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await login(formData);
      console.log('Full server response:', response);

      if (response.success) {
        const user = response.user;
        const accessToken = response.access_token;

        if (formData.remember) {
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('access_token', accessToken);
        } else {
          sessionStorage.setItem('user', JSON.stringify(user));
          sessionStorage.setItem('access_token', accessToken);
        }
        setUser(user);
        
     
        setTimeout(() => {
          alert('Bạn đã đăng nhập thành công');
          navigate(user.role === 'admin' ? '/Dashboard' : '/');
        }, 1000); // Thời gian delay (không bắt buộc)
      } else {
        setErrorMessage(response.message || 'Đăng nhập không thành công.');
      }
    } catch (error) {
      console.error('Unexpected error during login:', error);
      setErrorMessage('Lỗi kết nối mạng. Vui lòng thử lại sau.');
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await forgotPassword(formData.email);
      localStorage.setItem('email', formData.email);
      if (response.success) {
        alert(response.message);
        navigate('/Otp'); // Chuyển hướng tới trang nhập OTP
      } else {
        setErrorMessage(response.message);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Lỗi khi gửi yêu cầu quên mật khẩu.');
    }
  };

  const handleLoginSuccess = async (response) => {
    const token = response.credential;  // Google id_token

    try {
      const apiResponse = await fetch('https://api.pethaven.website/auth/google/callback', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, // Gửi token Google vào header
        }
      });
      const data = await apiResponse.json();
      
      if (data.success) {
        // Lưu access token vào localStorage
        localStorage.setItem('access_token', data.access_token);
        // Lưu thông tin người dùng vào state hoặc context
        console.log('Đăng nhập thành công', data.user);
      } else {
        console.error('Đăng nhập thất bại', data.message);
      }
    } catch (error) {
      console.error('Lỗi khi kết nối với API:', error);
    }
  };
  

  const handleGoogleLoginFailure = (error) => {
    console.log('Google login failed:', error);
    setErrorMessage('Đăng nhập Google thất bại. Vui lòng thử lại.');
  };

  return (
    <div className="wrap">
      <div className="containerLogin" id="container">
        <div className="form-container sign-in-container">
          <form onSubmit={handleLogin} className='formLogin'>
            <h1 className="headH1">ĐĂNG NHẬP</h1>
            <div className="social-container">
              {/* <a href="#" className="social"><i className="fab fa-facebook-f"></i></a> */}
              {/* Đăng nhập bằng Google */}
              <GoogleOAuthProvider clientId="44194627684-n32osudvpjvgn6kd2ps5ngnaiph0bai2.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={handleLoginSuccess}
                  onFailure={handleGoogleLoginFailure}
                  useOneTap // Tùy chọn bật One-Tap login nếu muốn
                />
              </GoogleOAuthProvider>
              {/* <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a> */}
            </div>
            <input
              type="email"
              name="email"
              className="username"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="password"
              name="password"
              className="username"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <Link onClick={handleForgotPassword} className="social">Quên mật khẩu?</Link>
            <button type="submit" className="buttonLogin">ĐĂNG NHẬP</button>
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay-Login"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
