import React, { useState } from 'react';
import axios from 'axios';

const LienHe = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false); // Hiển thị trạng thái khi gửi
  const [responseMessage, setResponseMessage] = useState(null); // Kết quả gửi
  const [errorMessage, setErrorMessage] = useState(null); // Lỗi nếu có

  // Cập nhật dữ liệu khi người dùng nhập
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // Xử lý khi gửi form
  const handleSubmit = async (e) => {
    e.preventDefault(); // Ngăn chặn refresh trang
    setLoading(true);
    setResponseMessage(null);
    setErrorMessage(null);

    try {
      const response = await axios.post('https://api.pethaven.website/api/contact', formData, {
        headers: { 'Content-Type': 'application/json' },
      });

      setResponseMessage(response.data.message || 'Gửi liên hệ thành công!');
      alert("Bạn đã liên hệ thành công")
      setFormData({ name: '', email: '', message: '' }); // Reset form
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Có lỗi xảy ra khi gửi liên hệ.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        {/* Phần banner */}
        <div className="banner-service">
          <div className="banner-service-text">
            <h1 className="main-title">Liên hệ</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">Trang Chủ</a></li>
                <li className="breadcrumb-item active-pro" aria-current="page">Liên hệ</li>
              </ol>
            </nav>
          </div>
        </div>

        {/* Phần form liên hệ */}
        <div className="container distance-contact">
          <div className="row">
            <div className="col-md-12 mb-6">
              <div className="contact-header">
                <h2 className="text-center main-heading">Liên hệ với chuyên gia để <br />được tư vấn</h2>
              </div>
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <input
                        type="text"
                        className="form-control-contact"
                        id="name"
                        placeholder="Nhập họ tên"
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <input
                        type="email"
                        className="form-control-contact"
                        id="email"
                        placeholder="Nhập địa chỉ email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-4">
                    <textarea
                      className="form-control-contact-textarea"
                      id="message"
                      rows={4}
                      placeholder="Nhập nội dung"
                      required
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </div>
                  <button type="submit" className="btn btn-warning w-30" disabled={loading}>
                    {loading ? 'Đang gửi...' : 'Gửi thông tin'}
                  </button>
                </form>
              </div>
           
            </div>
          </div>

          {/* Phần bản đồ và thông tin liên hệ */}
          <div className="map-container">
            <iframe
              className="map-embed"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31347.553298010487!2d106.59103797910154!3d10.853782900000011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752b6c59ba4c97%3A0x535e784068f1558b!2zVHLGsOG7nW5nIENhbyDEkeG6s25nIEZQVCBQb2x5dGVjaG5pYw!5e0!3m2!1svi!2s!4v1727518173489!5m2!1svi!2s"
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen
            />
            <div className="contact-info-box">
              <h4>Thông tin liên hệ</h4>
              <p><i className="fas fa-location-dot" /> 1073/23 Cách Mạng Tháng 8, P.7, Q. Tân Bình, TPHCM</p>
              <p><i className="fas fa-phone" /> (+84) 0313728397</p>
              <p><i className="fas fa-clock" /> Thứ 2 - Thứ 6: 09:00 - 18:00</p>
              <p><i className="fas fa-envelope" /> info@themona.global</p>
              <div className="contact-icons">
                <a href="#" className="btn btn-outline-secondary btn-sm"><i className="fab fa-facebook-f" /></a>
                <a href="#" className="btn btn-outline-secondary btn-sm"><i className="fab fa-twitter" /></a>
                <a href="#" className="btn btn-outline-secondary btn-sm"><i className="fab fa-pinterest-p" /></a>
                <a href="#" className="btn btn-outline-secondary btn-sm"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>

          {/* Phần đăng ký nhận tin tức */}
          <div className="subscribe-container">
            <div className="col-md-12 mb-0.5">
              <div className="row">
                <div className="col-md-6">
                  <i className="fas fa-envelope" />
                  <h4>Đăng ký ngay để được nhận tin tức mới nhất.</h4>
                </div>
                <div className="col-md-6">
                  <form className="subscribe-form">
                    <input type="email" placeholder="Địa chỉ email..." required />
                    <button type="submit"><i className="fas fa-paper-plane" /></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LienHe;
