import React, { useEffect, useState } from 'react';
import { fetchNews, deleteNews } from '../../services/Admin/NewService'; // Thêm hàm deleteNews từ service
import { Link } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin'; // Import UserInfo component

const NewAdmin = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const getNews = async () => {
    setLoading(true);
    try {
      const { data, last_page } = await fetchNews(search, page, perPage);
      setNews(data);
      setTotalPages(last_page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching news:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getNews();
  }, [search, page, perPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    getNews();
  };

  const handleDelete = async (id) => {
    if (window.confirm('Bạn có chắc muốn xóa tin tức này không?')) {
      try {
        await deleteNews(id); // Gọi API để xóa tin tức
        setNews(news.filter((item) => item.id !== id)); // Cập nhật danh sách sau khi xóa
      } catch (error) {
        console.error('Error deleting news:', error);
      }
    }
  };

  if (loading) return  <div className="loading-container">
  <svg viewBox="25 25 50 50" className="spinner">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
</div>;
  if (news.length === 0) return <div>Không có tin tức nào.</div>;

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="content flex-grow-1">
        <div className="header">
          <div className="text-left">
            <h4 className="main-title-adminindex">Danh sách tin tức</h4>
          </div>
          <UserInfo />
        </div>

        <div className="search-bar-container">
          <div className="search-bar">
            <input
              className="form-control form-addsp"
              placeholder="Tìm tin tức"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className="btn btn-dark" onClick={handleSearch}>
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>

        <form className="form-inline text-left">
          <Link to="/add-news">
            <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#addNewsModal">
              Thêm tin tức
            </button>
          </Link>
        </form>

        <div className="table-container">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>STT</th>
                <th>Ảnh</th>
                <th>Tên Tin Tức</th>
                <th>Thể Loại</th>
                <th>Sửa</th>
                <th>Xóa</th>
              </tr>
            </thead>
            <tbody>
              {news.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img alt={`Product Image ${index + 1}`} height={50} src={`https://api.pethaven.website/images/news/${item.image}`} width={50} />
                  </td>
                  <td>{item.title}</td>
                  <td>{item.category_name}</td>
                  <td>
                    <Link to={`/edit-news/?id=${item.id}`} onClick={() => console.log(`Editing service ${item.id}`)}>
                      <i className="fas fa-edit text-success" />
                    </Link>
                  </td>
                  <td>
                    <i
                      className="fas fa-trash-alt text-danger"
                      onClick={() => handleDelete(item.id)} // Gọi hàm handleDelete khi bấm xóa
                      style={{ cursor: 'pointer' }}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                  Previous
                </button>
              </li>
              <li className="page-item">
                <span className="page-link">Page {page} of {totalPages}</span>
              </li>
              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NewAdmin;
