import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../components/SliderBar';
import UserInfo from '../../components/UserInfoAdmin';

const OrderAdmin = () => {
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);

  // Trạng thái có thể thay đổi
  const statuses = {
    pending: 'Chờ xử lý',
    prepare: 'Đã xác nhận',
    shipping: 'Đang giao hàng',
    success: 'Hoàn thành',
    cancle: 'Hủy',
    return: 'Trả hàng',
  };

  // API lấy danh sách đơn hàng
  const fetchOrders = async (search = '', page = 1, perPage = 10, fromDate = '', toDate = '') => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.pethaven.website/admin/order`, {
        params: {
          search,
          page,
          per_page: perPage,
          from_date: fromDate,
          to_date: toDate,
        },
      });
      setOrders(response.data.data);
      setTotalPages(response.data.last_page);
      setLoading(false);
    } catch (error) {
      console.error('Lỗi lấy danh sách đơn hàng:', error);
      setLoading(false);
    }
  };

  // Xử lý trạng thái không hợp lệ
  const isValidStatusChange = (currentStatus, newStatus) => {
    const invalidChanges = {
      pending: ['pending', 'cancle'],
      prepare: ['pending'],
      shipping: ['pending', 'prepare', 'cancle'],
      success: ['pending', 'prepare', 'shipping', 'cancle'],
      cancle: ['pending', 'prepare', 'shipping', 'success', 'return'],
      return: ['pending', 'prepare', 'shipping', 'success', 'cancle'],
    };

    return !invalidChanges[currentStatus]?.includes(newStatus);
  };

  // Hàm cập nhật trạng thái đơn hàng
  const updateOrderStatus = async (orderId, currentStatus, newStatus) => {
    if (!isValidStatusChange(currentStatus, newStatus)) {
      alert('Thay đổi trạng thái không hợp lệ!');
      return;
    }

    if (window.confirm('Bạn có chắc chắn muốn thay đổi trạng thái đơn hàng không?')) {
      try {
        await axios.put(`https://api.pethaven.website/admin/order/update/${orderId}`, { status: newStatus });
        alert('Cập nhật trạng thái thành công!');
        fetchOrders(searchTerm, page, perPage, fromDate, toDate); // Làm mới danh sách đơn hàng
      } catch (error) {
        console.error('Lỗi cập nhật trạng thái:', error);
        alert('Có lỗi xảy ra, vui lòng thử lại sau.');
      }
    }
  };

  useEffect(() => {
    fetchOrders(searchTerm, page, perPage, fromDate, toDate);
  }, [searchTerm, page, perPage, fromDate, toDate]);

  // Xử lý tìm kiếm
  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    fetchOrders(searchTerm, 1, perPage, fromDate, toDate);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchOrders(searchTerm, newPage, perPage, fromDate, toDate);
  };

  if (loading) return <div className="loading-container">Đang tải...</div>;

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />
        <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4">
          <div className="header">
            <h4 className="main-title-adminindex">Danh sách đơn hàng</h4>
            <div className="d-flex align-items-center">
              <div className="search-bar">
                <input
                  placeholder="Tìm kiếm"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
                <button onClick={handleSearch}>
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <UserInfo />
          </div>

          <section className="table-section">
            <table>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Mã Đơn Hàng</th>
                  <th>Tên Khách Hàng</th>
                  <th>Ngày Đặt</th>
                  <th>Tổng Tiền</th>
                  <th>Trạng Thái</th>
                  <th>Hành động</th>
                </tr>
              </thead>
              <tbody>
                {orders.length > 0 ? (
                  orders.map((order, index) => (
                    <tr key={order.id}>
                      <td>{(page - 1) * perPage + index + 1}</td>
                      <td>#{order.id}</td>
                      <td>{order.user_fullname}</td>
                      <td>{new Date(order.created_at).toLocaleDateString()}</td>
                      <td>{order.total_money.toLocaleString()} VND</td>

                      <td>
                        <select
                          value={order.status}
                          onChange={(e) =>
                            updateOrderStatus(order.id, order.status, e.target.value)
                          }
                        >
                          {Object.entries(statuses).map(([key, label]) => (
                            <option
                              key={key}
                              value={key}
                              disabled={!isValidStatusChange(order.status, key)} // Disable nếu không hợp lệ
                            >
                              {label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <Link to={`/edit-order/?id=${order.id}`}>
                          <i className="fas fa-edit text-success" />
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">Không có đơn hàng nào</td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>

          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                  Trước
                </button>
              </li>
              <li className="page-item">
                <span className="page-link">
                  Trang {page} / {totalPages}
                </span>
              </li>
              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                  Tiếp
                </button>
              </li>
            </ul>
          </nav>
        </main>
      </div>
    </div>
  );
};

export default OrderAdmin;
