import axios from 'axios';

// Định nghĩa URL cơ sở
const API_BASE_URL = 'https://api.pethaven.website/admin'; // Thay đổi URL này cho phù hợp

// Lấy thông tin Dashboard

// Lấy danh sách sản phẩm
export const fetchComments = async (search = '', page = 1, perPage = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/comment`, {
            params: { search, page, perPage },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};
export const deleteComment = async (CommentId) => {
    const response = await axios.delete(`${API_BASE_URL}/comment/delete/${CommentId}`);
    return response.data; // Bạn có thể trả về dữ liệu phản hồi nếu cần
};