import React, { useState, useEffect } from 'react';

const BookingHistory = () => {
  const [bookingHistory, setBookingHistory] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('user'))?.id;
  
    if (!userId) {
      setError('Bạn cần đăng nhập để xem lịch sử đặt lịch.');
      return;
    }
  
    fetch(`https://api.pethaven.website/api/booking-history?user_id=${userId}`)
      .then(response => {
        if (!response.ok) throw new Error('Lỗi khi lấy lịch sử đặt lịch');
        return response.json();
      })
      .then(data => {
        if (data.status === 'success') {
          // Thêm trạng thái isCancelled vào mỗi booking
          const updatedBookingHistory = data.data.map(booking => ({
            ...booking,
            isCancelled: booking.status === 'canceled'
          }));
          setBookingHistory(updatedBookingHistory);
        } else {
          setError(data.message || 'Lỗi khi lấy lịch sử đặt lịch');
        }
      })
      .catch(err => setError(err.message || 'Lỗi kết nối tới API'));
  }, []);

  const handleCancelBooking = (bookingId) => {
    if (window.confirm("Bạn chắc chắn muốn hủy đặt lịch này không?")) {
      fetch(`https://api.pethaven.website/api/booking/${bookingId}/cancel`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            alert(data.message);
            
            // Cập nhật bookingHistory để đánh dấu booking đã hủy
            setBookingHistory(prevHistory =>
              prevHistory.map(booking =>
                booking.id === bookingId
                  ? { ...booking, isCancelled: true } // Đánh dấu booking là đã hủy
                  : booking
              )
            );
          } else {
            alert(data.message || "Có lỗi xảy ra!");
          }
        })
        .catch(error => console.error("Error:", error));
    }
  };

  return (
    <>
      <div className="booking-container">
        <div className="booking-history-container">
          <h4>Đặt Lịch Cho Thú Cưng</h4>
          <p>Xem và quản lý các lịch đặt của thú cưng</p>
          {error && <p className="text-danger">{error}</p>}
          <table className="booking-table">
            <thead>
              <tr>
                <th>ID Đơn Hàng</th>
                <th>Ngày Đặt Lịch</th>
                <th>Giờ Đặt Lịch</th> {/* Thêm cột Giờ Đặt Lịch */}
                <th>Tổng Tiền</th>
                <th>Trạng Thái</th>
                <th>Thú Cưng</th>
                <th>Số Điện Thoại</th>
                <th>Email</th>
                <th>Dịch Vụ</th>
                <th>Hành Động</th>
              </tr>
            </thead>
            <tbody>
              {bookingHistory.length > 0 ? (
                bookingHistory.map((booking, index) => (
                  <tr key={index}>
                    <td>#{booking.id}</td>
                    <td>{new Date(booking.booking_date).toLocaleDateString()}</td>
                    {/* Cập nhật giờ hiển thị */}
                    <td>{booking.booking_time || 'N/A'}</td>
                    <td>{booking.total_price.toLocaleString()} VNĐ</td>
                    <td>{booking.status}</td>
                    <td>{booking.pet?.name || 'N/A'}</td>
                    <td>{booking.phone}</td>
                    <td>{booking.email}</td>
                    <td>{booking.service?.name || 'N/A'}</td>
                    <td>
                      {new Date(booking.booking_date) > new Date() && !booking.isCancelled ? (
                        <button onClick={() => handleCancelBooking(booking.id)} className="btn-cancel">
                          Hủy
                        </button>
                      ) : (
                        <span>{booking.isCancelled ? "Đã hủy" : "Không thể hủy"}</span>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" style={{ textAlign: 'center' }}>
                    Không có lịch sử đặt lịch.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default BookingHistory;
