import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SliderBar';
import UserInfo from '../../components/UserInfoAdmin';

const AddServiceAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const id = getQueryParam('id');

  const [serviceData, setServiceData] = useState({
    name: '',
    description: '',
    price: '',
    img: null,
    imgdetail: null, // Biến mới để lưu ảnh chi tiết
    imageUrl: null,
    imagedetailUrl: null, // URL ảnh chi tiết (khi chỉnh sửa)
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchServiceData = async () => {
      if (id) {
        try {
          const response = await fetch(`https://api.pethaven.website/admin/service/${id}`);
          const result = await response.json();
          if (response.ok) {
            setServiceData({
              ...result,
              imageUrl: result.img ? `https://api.pethaven.website/images/services/${result.img}` : null,
              imagedetailUrl: result.imgdetail
                ? `https://api.pethaven.website/images/services/${result.imgdetail}`
                : null,
            });
          } else {
            alert('Lỗi khi lấy dữ liệu dịch vụ: ' + result.message);
          }
        } catch (error) {
          alert('Lỗi kết nối API: ' + error);
        }
      }
    };

    fetchServiceData();
  }, [id]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setServiceData({
      ...serviceData,
      [id]: value,
    });
  };

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    setServiceData({
      ...serviceData,
      [id]: files[0], // Cập nhật file tương ứng (img hoặc imgdetail)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!serviceData.name || !serviceData.price) {
      alert('Vui lòng điền đầy đủ thông tin.');
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('name', serviceData.name);
    formData.append('description', serviceData.description);
    formData.append('price', serviceData.price);
    if (serviceData.img) {
      formData.append('img', serviceData.img); // Thêm ảnh chính
    } else if (id) {
      formData.append('img', serviceData.imageUrl); // Hoặc tên ảnh cũ
    }
    if (serviceData.imgdetail) {
      formData.append('imgdetail', serviceData.imgdetail); // Thêm ảnh chi tiết
    } else if (id) {
      formData.append('imgdetail', serviceData.imagedetailUrl); // Hoặc tên ảnh chi tiết cũ
    }

    try {
      const url = `https://api.pethaven.website/admin/service/${id ? `update/${id}` : 'add'}`;
      const options = {
        method: id ? 'POST' : 'POST',
        headers: id
          ? {
            'X-HTTP-Method-Override': 'PUT',
          }
          : {},
        body: formData,
        mode: 'cors',
      };

      const response = await fetch(url, options);
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        const text = await response.text();
        console.error('Server trả về kết quả không phải JSON:', text);
        alert('Lỗi: Server trả về không phải JSON. Chi tiết: ' + text);
        return;
      }

      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        navigate('/ServiceAdmin');
      } else {
        alert('Đã xảy ra lỗi: ' + result.message);
      }
    } catch (error) {
      alert('Lỗi kết nối API: ' + error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="content flex-grow-1">
          <div className="header">
            <div className="text-left">
              <h4 className="main-title-adminindex">
                {id ? 'Cập Nhật Dịch Vụ' : 'Thêm Dịch Vụ'}
              </h4>
            </div>
          </div>
          <div className="container mt-4">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Tên Dịch Vụ</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Nhập tên dịch vụ"
                  value={serviceData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>

              {serviceData.imageUrl && (
                <div className="mb-3">
                  <label className="form-label">Ảnh Hiện Tại</label>
                  <img
                    src={serviceData.imageUrl}
                    alt="Current Service"
                    style={{ width: '200px', height: 'auto' }}
                  />

                </div>
              )}

              <div className="mb-3">
                <label htmlFor="img" className="form-label">Hình Ảnh Chính</label>
                <input
                  type="file"
                  className="form-control"
                  id="img"
                  onChange={handleFileChange}
                />
              </div>

              {serviceData.imagedetailUrl && (
                <div className="mb-3">
                  <label className="form-label">Ảnh Chi Tiết Hiện Tại</label>
                  <img
                    src={serviceData.imagedetailUrl}
                    alt="Current Service Detail"
                    style={{ width: '200px', height: 'auto' }}
                  /> </div>
              )}

              <div className="mb-3">
                <label htmlFor="imgdetail" className="form-label">Ảnh Chi Tiết</label>
                <input
                  type="file"
                  className="form-control"
                  id="imgdetail"
                  onChange={handleFileChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="price" className="form-label">Giá</label>
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  placeholder="Nhập giá dịch vụ"
                  value={serviceData.price}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="description" className="form-label">Mô Tả</label>
                <textarea
                  className="form-control"
                  id="description"
                  rows="3"
                  value={serviceData.description}
                  onChange={handleInputChange}
                ></textarea>
              </div>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Đang xử lý...' : (id ? 'Cập Nhật Dịch Vụ' : 'Thêm Dịch Vụ')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddServiceAdmin;
