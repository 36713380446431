import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const OrderReturnMomo = () => {
    const { orderId } = useParams(); // Lấy orderId từ URL
    const [orderDetails, setOrderDetails] = useState(null);

    useEffect(() => {
        // Gọi API lấy thông tin đơn hàng
        const fetchOrderDetails = async () => {
            try {
                const response = await axios.get(`https://api.pethaven.website/admin/order/${orderId}`);
                setOrderDetails(response.data);
            } catch (error) {
                console.error('Lỗi khi lấy thông tin đơn hàng:', error);
            }
        };

        fetchOrderDetails();
    }, [orderId]);

    if (!orderDetails) {
        return <p>Đang tải thông tin đơn hàng...</p>;
    }

    return (
        <div className="order-return-container">
            <div className="order-success">
                <h1>Đặt hàng thành công! 🎉</h1>
                <p>
                    Cảm ơn bạn đã mua hàng. <Link to="/OrderHistory">Theo dõi đơn hàng</Link>.
                </p>
            </div>
            <div className="order-details">
                <h2>Chi Tiết Đơn Hàng</h2>
                <table className="order-table">
                    <thead>
                        <tr>
                            <th>Sản phẩm</th>
                            <th className="text-right">Số lượng</th>
                            <th className="text-right">Tổng giá</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderDetails.order_details.map((item, index) => (
                            <tr key={index}>
                                <td>{item.product_name}</td>
                                <td className="text-right">{item.quantity}</td>
                                <td className="text-right">{item.total_price} VNĐ</td>
                            </tr>
                        ))}
                        <tr>
                            <td>Tổng Số Phụ:</td>
                            <td colSpan="2" className="text-right">
                                {orderDetails.total_money} VNĐ
                            </td>
                        </tr>
                        <tr>
                            <td>Phương Thức Thanh Toán:</td>
                            <td colSpan="2" className="text-right">
                                {orderDetails.payment_method || 'N/A'}
                            </td>
                        </tr>
                        <tr className="total-row">
                            <td>Tổng Cộng:</td>
                            <td colSpan="2" className="text-right">
                                {orderDetails.total_money} VNĐ
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderReturnMomo;
