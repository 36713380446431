import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin'; // Import UserInfo component

const AddPetAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [petData, setPetData] = useState({
    name: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Lấy giá trị của query string
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const id = getQueryParam('id'); // Lấy giá trị 'id' từ query string

  // Hàm lấy dữ liệu thú cưng để sửa nếu có id
  useEffect(() => {
    const fetchPetData = async () => {
      if (id) {
        try {
          const response = await fetch(`https://api.pethaven.website/admin/pet/${id}`);
          const result = await response.json();
          if (response.ok) {
            setPetData(result); // Gán dữ liệu thú cưng
          } else {
            alert('Lỗi khi lấy dữ liệu thú cưng: ' + result.message);
          }
        } catch (error) {
          console.error('Lỗi kết nối API:', error);
        }
      }
    };
    fetchPetData();
  }, [id]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setPetData({
      ...petData,
      [id]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Lấy user từ localStorage và thêm user.id vào petData
    const user = JSON.parse(localStorage.getItem('user'));
    const updatedPetData = { ...petData, userId: user.id };

    try {
      const response = await fetch(`https://api.pethaven.website/admin/pet/${id ? `update/${id}` : 'add'}`, {
        method: id ? 'PUT' : 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedPetData)
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        navigate('/PetAdmin'); // Chuyển hướng sau khi thêm/sửa thú cưng thành công
      } else {
        alert('Đã xảy ra lỗi: ' + result.message);
      }
    } catch (error) {
      console.error('Lỗi kết nối API:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="content flex-grow-1">
          <div className="header d-flex justify-content-between align-items-center p-3">
            <h4 className="main-title-adminindex">Thêm Thú Cưng</h4>
            <UserInfo />
          </div>

          <div className="container mt-4">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Tên Thú Cưng</label>
                <input
                  type="text"
                  className="form-control form-addsp"
                  id="name"
                  placeholder="Nhập tên thú cưng"
                  value={petData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Đang xử lý...' : (id ? 'Cập Nhật Thú Cưng' : 'Thêm Thú Cưng')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPetAdmin;
