import React from 'react';

const Tabs = ({ activeTab, setActiveTab }) => {
  return (
    <ul className="nav nav-tabs mt-5">
      <li className="nav-item">
        <button
          className={`nav-link ${activeTab === 'description' ? 'active' : ''}`}
          onClick={() => setActiveTab('description')}
        >
          Mô tả
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${activeTab === 'review' ? 'active' : ''}`}
          onClick={() => setActiveTab('review')}
        >
          Đánh giá (2)
        </button>
      </li>
    </ul>
  );
};

export default Tabs;
