import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SliderBar';
import UserInfo from '../../components/UserInfoAdmin';

const AddNewsAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const id = getQueryParam('id');

  const [newsData, setNewsData] = useState({
    title: '',
    description1: '',
    description2: '',
    content: '',
    categorynew_id: '',
    image: null,
    imageUrl: null,
    user_id: null
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchNewsData = async () => {
      if (id) {
        try {
          const response = await fetch(`https://api.pethaven.website/admin/news/${id}`);
          const result = await response.json();
          if (response.ok) {
            setNewsData({
              ...result,
              imageUrl: result.image ? `https://api.pethaven.website/images/news/${result.image}` : null,
              categorynew_id: result.categorynew_id || '',
              user_id: result.user_id
            });
          } else {
            alert('Lỗi khi lấy dữ liệu tin tức: ' + result.message);
          }
        } catch (error) {
          alert('Lỗi kết nối API: ' + error);
        }
      }
    };

    fetchNewsData();
  }, [id]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://api.pethaven.website/admin/new-category');
        const result = await response.json();
        if (response.ok) {
          setCategories(result);
        } else {
          console.error('Lỗi khi lấy danh mục:', result.message);
        }
      } catch (error) {
        console.error('Lỗi kết nối API:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setNewsData({
      ...newsData,
      [id]: value
    });
  };

  const handleFileChange = (e) => {
    setNewsData({
      ...newsData,
      image: e.target.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newsData.title || !newsData.categorynew_id) {
      alert('Vui lòng điền đầy đủ thông tin.');
      return;
    }

    setIsSubmitting(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const updatedNewsData = { ...newsData, user_id: user.id };
    const formData = new FormData();
    formData.append('title', updatedNewsData.title);
    formData.append('description1', updatedNewsData.description1);
    formData.append('description2', updatedNewsData.description2);
    formData.append('content', updatedNewsData.content);
    formData.append('categorynew_id', updatedNewsData.categorynew_id);
    formData.append('user_id', updatedNewsData.user_id);
    if (updatedNewsData.image) {
      formData.append('image', updatedNewsData.image);
    }

    try {
      const url = `https://api.pethaven.website/admin/news/${id ? `update/${id}` : 'add'}`;
      const options = {
        method: id ? 'POST' : 'POST',
        headers: id
          ? {
            "X-HTTP-Method-Override": "PUT",
          }
          : {},
        body: formData,
        mode: 'cors'
      };

      const response = await fetch(url, options);
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        const text = await response.text();
        console.error('Server trả về kết quả không phải JSON:', text);
        alert('Lỗi: Server trả về không phải JSON. Chi tiết: ' + text);
        return;
      }

      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        navigate('/NewAdmin');
      } else {
        alert('Đã xảy ra lỗi: ' + result.message);
      }
    } catch (error) {
      alert('Lỗi kết nối API: ' + error);
      console.log('Lỗi:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="content flex-grow-1">
          <div className="header">
            <div className="text-left">
              <h4 className="main-title-adminindex">{id ? 'Cập Nhật Tin Tức' : 'Thêm Tin Tức'}</h4>
            </div>
            <UserInfo />
          </div>
          <div className="container mt-4">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="mb-3">
                <label htmlFor="title" className="form-label">Tên Tin Tức</label>
                <input
                  type="text"
                  className="form-control form-addsp"
                  id="title"
                  placeholder="Nhập tên tin tức"
                  value={newsData.title}
                  onChange={handleInputChange}
                  required
                />
              </div>
              {newsData.imageUrl && (
                <div className="mb-3">
                  <label className="form-label">Ảnh Hiện Tại</label>
                  <img src={newsData.imageUrl} alt="Current News" style={{ width: '200px', height: 'auto' }} />
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="image" className="form-label">Hình Ảnh</label>
                <input
                  type="file"
                  className="form-control form-addsp"
                  id="image"
                  onChange={handleFileChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="categorynew_id" className="form-label">Thể Loại</label>
                <select
                  className="form-select form-addsp"
                  id="categorynew_id"
                  value={newsData.categorynew_id}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Chọn thể loại</option>
                  {categories.length > 0 ? (
                    categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))
                  ) : (
                    <option value="">Không có thể loại</option>
                  )}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="description1" className="form-label">Mô Tả 1</label>
                <textarea
                  className="form-control form-addsp"
                  id="description1"
                  rows={3}
                  value={newsData.description1}
                  onChange={handleInputChange}
                  placeholder="Nhập mô tả 1"
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="description2" className="form-label">Mô Tả 2</label>
                <textarea
                  className="form-control form-addsp"
                  id="description2"
                  rows={3}
                  value={newsData.description2}
                  onChange={handleInputChange}
                  placeholder="Nhập mô tả 2"
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="content" className="form-label">Nội Dung</label>
                <textarea
                  className="form-control form-addsp"
                  id="content"
                  rows={5}
                  value={newsData.content}
                  onChange={handleInputChange}
                  placeholder="Nhập nội dung"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Đang xử lý...' : (id ? 'Cập Nhật Tin Tức' : 'Thêm Tin Tức')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewsAdmin;
