import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchPets, deletePet } from '../../services/Admin/NewService'; // Giả sử bạn có một service để fetch dữ liệu thú cưng
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin'; // Import UserInfo component

const PetAdmin = () => {
  const [pets, setPets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const getPets = async () => {
    setLoading(true);
    try {
      const { data, last_page } = await fetchPets(search, page, perPage);
      setPets(data);
      setTotalPages(last_page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching pets:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPets();
  }, [search, page, perPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); // Reset to first page when searching
    getPets();
  };

  const handleDelete = async (id) => {
    if (window.confirm('Bạn có chắc chắn muốn xóa thú cưng này?')) {
      try {
        await deletePet(id); // Gọi hàm xóa thú cưng
        getPets(); // Tải lại danh sách thú cưng
      } catch (error) {
        console.error('Error deleting pet:', error);
      }
    }
  };

  if (loading) return  <div className="loading-container">
  <svg viewBox="25 25 50 50" className="spinner">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
</div>;
  if (pets.length === 0) return <div>Không có thú cưng nào.</div>;

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />

        <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4">
          <div className="header">
            <div className="text-left">
              <h4 className="main-title-adminindex">Danh sách thú cưng</h4>
            </div>
            <UserInfo />
          </div>
          <div className="search-bar-container">
            <div className="search-bar">
              <input
                className="form-control"
                placeholder="Tìm thú cưng"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button className="btn btn-dark" onClick={handleSearch}>
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <form className="form-inline text-left">
            <Link to="/AddPetAdmin">
              <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#addPetModal">
                Thêm thú cưng
              </button>
            </Link>
          </form>
          <section className="table-section mt-4">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên Pet</th>
                  <th>Sửa</th>
                  <th>Xóa</th>
                </tr>
              </thead>
              <tbody>
                {pets.map((pet, index) => (
                  <tr key={pet.id}>
                    <td>{(page - 1) * perPage + index + 1}</td>
                    <td>{pet.name}</td>
                    <td>
                      <Link to={`/EditPetAdmin/?id=${pet.id}`} onClick={() => console.log(`Editing pet ${pet.id}`)}>
                        <i className="fas fa-edit text-success" />
                      </Link>
                    </td>
                    <td>
                      <i className="fas fa-trash-alt text-danger" onClick={() => handleDelete(pet.id)}></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination Controls */}
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                    Previous
                  </button>
                </li>
                <li className="page-item">
                  <span className="page-link">Page {page} of {totalPages}</span>
                </li>
                <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </section>
        </main>
      </div>
    </div>
  );
};

export default PetAdmin;
