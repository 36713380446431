import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Nhập useNavigate
import { register } from '../../services/AthSevices'; // Giả định bạn có một file api.js để thực hiện API call

const Register = () => {
  const [name, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate(); // Khởi tạo useNavigate

  const handleRegister = async (event) => {
    event.preventDefault();

    const body = { name, email, password, password_confirmation, phone, address };

    try {
      const response = await register(body);
      console.log(response);

      // Nếu đăng ký thành công, hiển thị thông báo và chuyển hướng đến trang login
      alert('Bạn đã đăng ký thành công ');
      navigate('/login', { state: { successMessage: 'Đăng ký thành công. Vui lòng đăng nhập.' } });
    } catch (error) {
      console.error('Registration failed:', error);

      // Kiểm tra xem có lỗi từ server trả về hay không
      if (error.response && error.response.data.errors) {
        const errors = error.response.data.errors;
        setErrorMessage(Object.values(errors).flat().join(', ')); // Kết hợp tất cả thông báo lỗi
      } else {
        setErrorMessage('Đăng ký thất bại. Vui lòng thử lại.'); // Thông báo lỗi chung
      }
    }
  };

  return (
    <main>
      <div className="wrap">
        <div className="containerRegister" id="container">
          <div className="form-container sign-in-container">
            <form onSubmit={handleRegister} className='formRegigter'>
              <h1 className="headH1">ĐĂNG KÝ</h1>

              <input
                type="text"
                placeholder="Username"
                 className="username"
                value={name}
                onChange={(e) => setUserName(e.target.value)} // Cập nhật state
                required
              />
              <input
                type="email"
                placeholder="Email"
                 className="username"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Cập nhật state
                required
              />
              <input
                type="text"
                placeholder="Số điện thoại"
                 className="username"
                value={phone}
                onChange={(e) => setPhone(e.target.value)} // Cập nhật state
                required
              />
              <input
                type="text"
                placeholder="Địa chỉ"
                 className="username"
                value={address}
                onChange={(e) => setAddress(e.target.value)} // Cập nhật state
                required
              />
              <input
                type="password"
                placeholder="Password"
                 className="username"
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Cập nhật state
                required
              />
              <input
                type="password"
                placeholder="Confirm Password"
                 className="username"
                value={password_confirmation}
                onChange={(e) => setConfirmPassword(e.target.value)} // Cập nhật state
                required
              />
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>} {/* Hiển thị thông báo lỗi */}
              <button type="submit" className="buttonLogin">ĐĂNG KÝ</button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlayRegister">
           
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;
