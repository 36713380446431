import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getNewsById } from '../../api/news';

const NewsDesciption = () => {
    const { id } = useParams(); // Lấy ID từ URL
    const [news, setNews] = useState(null); // State để lưu thông tin dịch vụ
    const [loading, setLoading] = useState(true); // Trạng thái tải dữ liệu
    const [error, setError] = useState(null); // Trạng thái lỗi nếu có
  useEffect(() => {
    const fetchService = async () => {
      try {
        const data = await getNewsById(id); // Gọi API lấy dịch vụ theo ID
        setNews(data); // Lưu dữ liệu vào state
      } catch (error) {
        setError('Không tìm thấy dịch vụ này.');
      } finally {
        setLoading(false); // Dừng trạng thái tải
      }
    };

    fetchService();
  }, [id]); // Chạy lại khi ID thay đổi

  if (loading) return  <div className="loading-container">
  <svg viewBox="25 25 50 50" className="spinner">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
</div>; // Hiển thị khi đang tải
  if (error) return <p>{error}</p>; // Hiển thị nếu có lỗi

  return (
    <>
      <div className="container mt-5 body-tt">
        <div className="row">
          
          <div className="col-md-8">
            <img src={`../img1/${news?.detail}`} alt={`${news?.title}`} className="post-image-cttt" />
            <h2 className="mt-4">{news?.title}</h2>
            <p>{news?.description}.</p>
           <br/>
            <p>{news?.content}</p>
        
            <div className="highlight-tags mt-4">
              <strong>Thẻ nổi bật:</strong>
              <button type="button" className="btn btn-warning-cttt">GROOMING</button>
              <button type="button" className="btn btn-warning-cttt">PET
                TRAINING</button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="post-sidebar mb-4">
              <h4>Bài viết mới</h4>
              <ul className="list-unstyled">
                <li className="listt">
                  <img src="../img/tintuc1.jpg" alt="Hình ảnh bài viết" className="post-image-cttt" />
                  <a href="#"> <i className="fas fa-comments"> o bình
                      luận</i> <br />
                    Watch how its heart out while
                    playing piano</a>
                </li>
                <li className="listt">
                  <img src="../img/tintuc1.jpg" alt="Hình ảnh bài viết" className="post-image-cttt" />
                  <a href="#"> <i className="fas fa-comments"> o bình
                      luận</i> <br />
                    Watch how its heart out while
                    playing piano</a>
                </li>
                <li className="listt">
                  <img src="../img/tintuc1.jpg" alt="Hình ảnh bài viết" className="post-image-cttt" />
                  <a href="#"> <i className="fas fa-comments"> o bình
                      luận</i> <br />
                    Watch how its heart out while
                    playing piano</a>
                </li>
                <li className="listt">
                  <img src="../img/tintuc1.jpg" alt="Hình ảnh bài viết" className="post-image-cttt" />
                  <a href="#"> <i className="fas fa-comments"> o bình
                      luận</i> <br />
                    Watch how its heart out while
                    playing piano</a>
                </li>
              </ul>
            </div>
            {/* Danh mục */}
            <div className="category-list-tt mb-4">
              <h4>Danh mục</h4>
              <Link href="#">Cat's Nap</Link>
              <Link href="#">Dog Food</Link>
              <Link href="#">Grooming</Link>
              <Link href="#">Health Care</Link>
              <Link href="#">Pet Care</Link>
            </div>
            {/* Thẻ nổi bật */}
            <div className="highlight-tags">
              <h4>Thẻ nổi bật</h4>
              <button type="button" className="btn btn-outline-secondary-cttt">GROOMING</button>
              <button type="button" className="btn btn-outline-secondary-cttt">HEALTH</button>
              <button type="button" className="btn btn-outline-secondary-cttt">PET
                CARE</button>
              <button type="button" className="btn btn-outline-secondary-cttt">PET
                TRAINING</button>
              <button type="button" className="btn btn-outline-secondary-cttt">PUPPY
                FOODS</button>
            </div>
          </div>
        </div>
        {/* Nút hiện khi lướt xuống */}
        <button id="scrollToTopBtn" title="Go to top">^</button>
      </div>
    </>
  )
}

export default NewsDesciption
