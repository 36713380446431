import React from 'react'
import { Link } from 'react-router-dom'
import { Dashday } from "./Dashday";
import { DashMonth } from "./DashMonth";
import { Dash } from "./Dash";
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin'; // Import UserInfo component

const BaoCaoDoanhThu = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-10 ms-sm-auto col-lg-10 px-md-4">
            <div className="header">
              <h4 className="main-title-adminindex">Báo cáo doanh thu</h4>
              <div className="d-flex align-items-center">
              </div>
              <UserInfo />
            </div>
            <div className="container mt-4">


              <div className="row mt-4">
                <div className="col-md-8 offset-md-2">
                  <Dashday />
                </div>

              </div>

              <div className="row mt-4">
                <div className="col-md-8 offset-md-2">
                  <DashMonth />
                </div>

              </div>

              <div className="row mt-4">
                <div className="col-md-8 offset-md-2">
                  <Dash />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BaoCaoDoanhThu
