import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const GoogleAuth = () => {
 const handleLoginSuccess = async (response) => {
  if (response && response.credential) {
    console.log('Google Token:', response.credential);  // In token ra console
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    
      const res = await fetch('https://api.pethaven.website/auth/google/callback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,  // Gửi CSRF token nếu có
        },
        body: JSON.stringify({ token: response.credential }), // Đảm bảo bạn gửi đúng token
      });

      const data = await res.json();
      console.log(data); // Xử lý dữ liệu trả về từ backend
    } catch (error) {
      console.error('Error during login:', error);
    }
  } else {
    console.error('Google Login failed: No token received');
  }
};


  const handleLoginFailure = (error) => {
    console.log('Login failed:', error);
  };

  return (
    <GoogleOAuthProvider clientId="44194627684-n32osudvpjvgn6kd2ps5ngnaiph0bai2.apps.googleusercontent.com">
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onFailure={handleLoginFailure}
        useOneTap // Tùy chọn bật One-Tap login nếu muốn
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
