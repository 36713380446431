import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getService } from '../../api/service'; // Đảm bảo đường dẫn đúng đến file chứa getService

const Service = () => {
  const [services, setServices] = useState([]); // State để lưu danh sách dịch vụ
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      const data = await getService(1, '', 9, ''); // Gọi hàm getService với tham số mặc định

      if (data && data.status === 'success') {
        setServices(data.data.data); // Lưu dữ liệu dịch vụ vào state
      } else {
        console.error('Lỗi khi lấy danh sách dịch vụ');
      }
      setLoading(false);
    };

    fetchServices();
  }, []);
  if (loading) return  <div className="loading-container">
  <svg viewBox="25 25 50 50" className="spinner">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
</div>; 

  return (
    <>
      <div>
        <div className="banner-service">
          <div className="banner-service-text">
            <h1 className="main-title">Dịch vụ</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link href="#">Trang Chủ</Link></li>
                <li className="breadcrumb-item active-pro" aria-current="page">Dịch Vụ</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="pet-dichvu">
          <div className="container">
            <div className="row">
              {
                services.map((service) => (
                  <div className="col-md-3 margin-service-edit" key={service.id}>
                    <div className="service-card text-center p-3">
                      <div className="image-wrapper">
                        <img src={`https://api.pethaven.website/images/services/${service.img}`} className="rounded-circle mb-3" alt={service.name} />
                      </div>
                      <h4 className="content-service">{service.name}</h4>
                      <p className="content-description">{service.description}</p>
                      <br/>
                      <div className="hover-text">
                        <Link to={`/Service/${service.id}`} className="read-more">Đọc thêm</Link>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Service;
