const API_BASE_URL = 'https://api.pethaven.website/api';


export const getNews = async (page = 1, sort = '', perPage = 9, search = '') => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/news?page=${page}&perPage=${perPage}`, 
      {
        headers: {
          Accept: 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch products');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching products:', error);
    return null;
  }
};

export const getNewsById = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/news/${id}`, {
      headers: {
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Product not found');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching product:', error);
    throw error;
  }
};
export const getLatestNews = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/latest-news`, {
      headers: {
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch latest news');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching latest news:', error);
    return null;
  }
};
