const API_BASE_URL = 'https://api.pethaven.website/api';


// API để lấy sản phẩm theo trang và sắp xếp
export const getProducts = async (page = 1, sort = '', perPage = 9, search = '') => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/product?page=${page}&perPage=${perPage}&sort=${sort}&search=${encodeURIComponent(search)}`, 
      {
        headers: {
          Accept: 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch products');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching products:', error);
    return null;
  }
};


// API để lấy sản phẩm chi tiết theo ID
export const getProductById = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/detail/${id}`, {
      headers: {
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Product not found');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching product:', error);
    throw error;
  }
};

// API để lấy sản phẩm theo danh mục và phân trang
export const getProductsByCategory = async (categorySlug, page = 1, perPage = 9) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/category/${categorySlug}?page=${page}&perPage=${perPage}`,
      {
        headers: {
          Accept: 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch products by category');
    }

    return await response.json();  // Trả về dữ liệu JSON từ API
  } catch (error) {
    console.error('Error fetching products by category:', error);
    return null; // Trả về null hoặc giá trị mặc định nếu có lỗi
  }
};
// API để lấy sản phẩm liên quan theo ID sản phẩm hiện tại
export const getRelatedProducts = async (productId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/product/${productId}/related`, {
      headers: {
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch related products');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching related products:', error);
    return []; // Trả về mảng rỗng nếu gặp lỗi
  }
};


// File: api/user.js (hoặc file chứa hàm gọi API)

// File: api/user.js
export const updateUserProfile = async (userId, formData) => {
  const response = await fetch(`https://api.pethaven.website/api/user/profile/${userId}`, {
    method: 'POST', // hoặc 'PUT' nếu server hỗ trợ
   
      headers: {
        "Content-Type": "multipart/form-data",
        "X-HTTP-Method-Override": "PUT", // Dùng header để báo đây là PUT
      }, // Sử dụng nếu server yêu cầu

    body: formData,
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error('Error response text:', errorText);
    throw new Error(`Failed to update user profile: ${response.status} ${response.statusText}`);
  }

  return response.json(); // Parse JSON từ phản hồi nếu thành công
};

export const fetchNewProducts = async (perPage = 10) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products/new?perPage=${perPage}`);
    if (!response.ok) {
      throw new Error('Failed to fetch new products');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// Fetch sản phẩm hot
export const fetchHotProducts = async (perPage = 10) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products/hot?perPage=${perPage}`);
    if (!response.ok) {
      throw new Error('Failed to fetch hot products');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};



