import React, { useState, useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { useNavigate } from 'react-router-dom'; 
import { getServices, bookService, getPets } from '../../api/servicebooking';
import { useUser } from '../../context/UserContext';


const Booking = () => {
  const [services, setServices] = useState([]);
  const [pets, setPets] = useState([]);
  const [selectedPet, setSelectedPet] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState(null);
  const [bookingTime, setBookingTime] = useState(''); // Thêm bookingTime
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [bookedTimes, setBookedTimes] = useState([]); 
  const navigate = useNavigate(); 
  const { user, setUser } = useUser();

  useEffect(() => {
    // Lấy thông tin từ localStorage nếu user là null
    if (!user) {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser) {
        setUser(storedUser);
      }
    }

    const fetchData = async () => {
      try {
        const [servicesData, petsData] = await Promise.all([getServices(), getPets()]);
        setServices(servicesData);
        setPets(petsData);
      } catch (error) {
        console.error('Lỗi khi lấy dữ liệu:', error);
      }
    };
    fetchData();
  }, [user, setUser]);

  const handlePetChange = (e) => {
    const pet = pets.find((p) => p.id === parseInt(e.target.value));
    setSelectedPet(pet);
  };

  const handleServiceChange = (e) => {
    const service = services.find((s) => s.id === parseInt(e.target.value));
    setSelectedService(service);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedPet || !selectedService || !bookingTime || !user || !user.id) {
      setError('Vui lòng điền đầy đủ thông tin trước khi đặt lịch');
      return;
    }

    const formattedDate = date ? date.toISOString().split('T')[0] : null;

    const bookingData = {
      user_id: user.id,
      pet_id: selectedPet?.id,
      service_id: selectedService?.id,
      booking_date: formattedDate,
      booking_time: bookingTime,
      phone,
      email,
    };

    try {
      const response = await bookService(bookingData);

      if (response.status === 'error') {
        setError(response.message);
      } else {
        alert('Đặt lịch thành công!'); // Hiển thị alert
        setError(null);
        navigate('/BookingHistory'); // Chuyển hướng sang BookingHistory
      }
    } catch (err) {
      setError('Khung giờ này đã được đặt hoặc ngày không phù hợp');
    }
  };

  
  const isTimeBooked = (time) => bookedTimes.includes(time);
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100 booking-margin">
      <div className="row">
        <div className="col-md-6 image-section">
          <img src="../img/Let’s make.png" alt="Pet Image" className="rounded" />
        </div>
        <div className="col-md-6">
          <div className="form-box">
            <h2 className="form-title">Đặt lịch</h2>
            {message && <p className="alert alert-success">{message}</p>}
            {error && <p className="alert alert-danger">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="pet-select" className="form-label">Chọn thú cưng</label>
                <select
                  id="pet-select"
                  className="form-select"
                  onChange={handlePetChange}
                >
                  <option value="">Chọn một thú cưng</option>
                  {pets.map((pet) => (
                    <option key={pet.id} value={pet.id}>
                      {pet.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="service-select" className="form-label">Chọn dịch vụ</label>
                <select
                  id="service-select"
                  className="form-select"
                  onChange={handleServiceChange}
                >
                  <option value="">Chọn một dịch vụ</option>
                  {services.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.name}
                    </option>
                  ))}
                </select>
              </div>

              {selectedService && (
                <p id='service-price' className="mt-2">Giá: {selectedService.price.toLocaleString('vi-VN')} VND</p>
              )}

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">Số điện thoại *</label>
                <input
                  type="text"
                  id="phone"
                  className="form-control"
                  placeholder="Nhập số điện thoại"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email *</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Nhập email của bạn"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="birthdate" className="form-label">Chọn ngày</label>
                <Flatpickr
                  className="form-control"
                  id="birthdate"
                  value={date}
                  onChange={(selectedDates) => setDate(selectedDates[0])}
                />
              </div>

              <div className="mb-3">
    <label htmlFor="booking-time" className="form-label">Chọn giờ hẹn</label>
    <select
      id="booking-time"
      className="form-select"
      value={bookingTime}
      onChange={(e) => setBookingTime(e.target.value)}
    >
      <option value="">Chọn giờ hẹn</option>
      {['10:00', '12:00', '14:00', '16:00', '18:00'].map((time) => (
        <option
          key={time}
          value={time}
          style={{
            backgroundColor: isTimeBooked(time) ? 'gray' : 'white', 
            color: isTimeBooked(time) ? 'white' : 'black',
          }}
          disabled={isTimeBooked(time)} 
        >
          {time}
        </option>
      ))}
    </select>
  </div>

              <button type="submit" className="submit-btn">Gửi</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
