import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import React, { useEffect, useState } from 'react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false, // Ẩn chú thích vì chỉ có một cột
        },
        title: {
            display: true,
            text: 'Tổng doanh thu theo năm',
        },
    },
};

export function MyChartTotal({ year }) {
    const [data, setData] = useState({
        labels: [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ],
        datasets: [
            {
                label: 'Total Revenue',
                data: Array(12).fill(0),
                backgroundColor: 'rgba(54, 162, 235, 0.7)',
            },
        ],
    });

    const fetchTotalRevenue = async (year) => {
        try {
            const response = await fetch(`https://api.pethaven.website/admin/revenue/total/year/${year}`);
            if (!response.ok) throw new Error('Network response was not ok');

            const revenueData = await response.json();
            console.log('Dữ liệu doanh thu nhận được:', revenueData); // Kiểm tra dữ liệu

            // Kiểm tra xem dữ liệu monthly_revenue_by_month_in_year có tồn tại hay không
            if (revenueData.monthly_revenue_by_month_in_year) {
                setData((prevData) => ({
                    ...prevData,
                    datasets: [
                        {
                            ...prevData.datasets[0],
                            data: Object.values(revenueData.monthly_revenue_by_month_in_year),
                        },
                    ],
                }));
            } else {
                console.error('Dữ liệu doanh thu không có cấu trúc mong đợi.');
            }
        } catch (error) {
            console.error('Lỗi khi lấy dữ liệu doanh thu:', error);
        }
    };

    // Sử dụng useEffect để gọi API khi năm thay đổi
    useEffect(() => {
        fetchTotalRevenue(year);
    }, [year]); // Chạy lại khi year thay đổi

    return <Bar options={options} data={data} />;
}
