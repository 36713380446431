import React, { useState, useEffect } from 'react';

const UserInfo = () => {
    const [userName, setUserName] = useState('');
    const [userAvatar, setUserAvatar] = useState('');

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            console.log('user:',user);
            setUserName(user.name);
            setUserAvatar(`https://api.pethaven.website/images/user/${user.img}` || '../../../img/avatar-gt1.jpg'); // Đường dẫn mặc định nếu không có ảnh
        }
    }, []);

    return (
        <div className="user-info">
            <i className="fas fa-bell" style={{ marginRight: '20px' }}></i>
            <i className="fas fa-cog" style={{ marginRight: '20px' }}></i>
            <img alt="User Avatar" height="40" src={userAvatar} width="40" />
            <span>{userName}</span>
        </div>
    );
};

export default UserInfo;
