import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin'; // Import UserInfo component

const EditUser = () => {
  const [userData, setUserData] = useState({
    name: '',
    image: '',
    email: '',
    address: '',
    phone: '',
    gender: '',
    dob: '',
    is_action: '0'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const id = getQueryParam('id');

  // Fetch dữ liệu người dùng
  useEffect(() => {
    const fetchUserData = async () => {
      if (id) {
        try {
          const response = await fetch(`https://api.pethaven.website/admin/user/${id}`);
          if (!response.ok) {
            const errorResult = await response.text();
            throw new Error('Lỗi khi lấy dữ liệu người dùng: ' + errorResult);
          }
          const result = await response.json();
          setUserData(result);
          console.log("User Data:", result); // In ra dữ liệu người dùng
        } catch (error) {
          console.error('Lỗi kết nối API:', error);
          alert(error.message);
        }
      }
    };
    fetchUserData();
  }, [id]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUserData({
      ...userData,
      [id]: value
    });
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    if (window.confirm("Bạn có chắc chắn muốn thay đổi trạng thái không?")) {
      setUserData({
        ...userData,
        is_action: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    console.log("Updating user with ID:", id);
    console.log("Request Body:", { is_action: userData.is_action });

    try {
      const response = await fetch(`https://api.pethaven.website/admin/user/update/${id}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ is_action: userData.is_action })
      });

      const result = await response.json();
      console.log("Response:", result); // In ra phản hồi từ server

      if (response.ok) {
        alert(result.message);
        navigate('/UserAdmin');
      } else {
        alert('Đã xảy ra lỗi: ' + result.message);
      }
    } catch (error) {
      console.error('Lỗi kết nối API:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="content flex-grow-1">
          <div className="header">
            <div className="text-left">
              <h4 className="main-title-adminindex">Sửa Thông Tin Khách Hàng</h4>
            </div>
            <UserInfo />
          </div>

          <div className="container mt-4">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Tên Khách Hàng</label>
                <input type="text" className="form-control" id="name" value={userData.name || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="image" className="form-label">Hình Ảnh</label>
                <div>
                  <img id="image" alt="Customer" height={100} width={100} src={userData.image || ''} />
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" value={userData.email || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="address" className="form-label">Địa Chỉ</label>
                <input type="text" className="form-control" id="address" value={userData.address || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">Số Điện Thoại</label>
                <input type="tel" className="form-control" id="phone" value={userData.phone || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="gender" className="form-label">Giới Tính</label>
                <input type="text" className="form-control" id="gender" value={userData.gender || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="dob" className="form-label">Ngày Sinh</label>
                <input type="date" className="form-control" id="dob" value={userData.dob || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="is_action" className="form-label">Trạng Thái</label>
                <select className="form-control" id="is_action" value={userData.is_action} onChange={handleStatusChange}>
                  <option value="1">Tạm khoá</option>
                  <option value="0">Đang hoạt động</option>
                </select>
              </div>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Đang cập nhật...' : 'Cập nhật'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
