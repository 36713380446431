import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/AthSevices';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Lấy email và OTP từ localStorage
  const email = localStorage.getItem('email');
  const otp = localStorage.getItem('otp');

  if (!email || !otp) {
    setErrorMessage('Email hoặc OTP không hợp lệ.');
    return;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage('Mật khẩu xác nhận không khớp');
      return;
    }

    const response = await resetPassword({ email, otp, password: newPassword, password_confirmation: confirmPassword });
    if (response.success) {
      alert(response.message);
      navigate('/login'); // Quay về trang đăng nhập
    } else {
      setErrorMessage(response.message); // Hiển thị thông báo lỗi
    }
  };

  return (
    <div className="wrap">
      <div className="containerLogin" id="container">
        <div className="form-container sign-in-container">
          <form onSubmit={handleSubmit} className='formLogin'>
            <h1 className="headH1">Đặt Lại Mật Khẩu</h1>
            <p>Nhập mật khẩu mới của bạn và xác nhận để hoàn tất.</p>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Mật khẩu mới"
              className="username"
            />
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Xác nhận mật khẩu mới"
              className="username"
            />
            <button type="submit" className="buttonLogin">Xác Nhận</button>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay-Login">
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
