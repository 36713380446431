import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyOtp } from '../../services/AthSevices';

const Otp = () => {
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Lấy email từ localStorage
  const email = localStorage.getItem('email');
  console.log('Email:', email);
  console.log('OTP:', otp);

  // Kiểm tra email hoặc otp có bị rỗng không
  useEffect(() => {
    if (!email || !otp) {
      setErrorMessage('Email hoặc mã OTP không được để trống.');
    }
  }, [email, otp]);

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    // Kiểm tra khi người dùng bấm submit
    if (!email || !otp) {
      setErrorMessage('Email hoặc mã OTP không được để trống.');
      return;
    }

    const response = await verifyOtp({ email, otp });
    localStorage.setItem('otp', otp);
    if (response.success) {
      alert(response.message);
      navigate('/ResetPassword');
    } else {
      setErrorMessage(response.message);
    }
  };

  return (
    <div className="wrap">
      <div className="containerLogin" id="container">
        <div className="form-container sign-in-container">
          <form onSubmit={handleOtpSubmit} className='formLogin'>
            <h1 className="headH1">Nhập Mã OTP</h1>
            <p>Chúng tôi đã gửi mã OTP đến email của bạn. Vui lòng nhập mã để tiếp tục.</p>
            <input
              type="text"
              className="username"
              placeholder="Nhập mã OTP"
              maxLength={6}
              value={otp}
              onChange={handleOtpChange}
            />
            <button type="submit" className="buttonLogin">Xác Nhận</button>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
           
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay-Login">
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
