import { Card, CardHeader, CardContent, CardTitle } from '../../components/ui/card';
import { MyChartDaytotal } from './MyChartDaytotal';

export function Dashday() {
    return (
        <Card>
            <CardHeader>
                <CardTitle>Doanh thu theo ngày</CardTitle>
            </CardHeader>
            <CardContent>
                <MyChartDaytotal />
            </CardContent>
        </Card>
    );
}
