import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
const EditOrderAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState(null);
  const [status, setStatus] = useState('');

  // Các trạng thái hợp lệ và trạng thái kế tiếp
  const statusFlow = {
    pending: ['prepare', 'cancle', 'return'],
    prepare: ['shipping', 'cancle', 'return'],
    shipping: ['success', 'cancle', 'return'],
    success: ['return'], // Có thể trả về sau khi thành công
    cancle: [], // Không thể thay đổi từ trạng thái "cancel"
    return: [], // Không thể thay đổi từ trạng thái "return"
  };

  // Lấy orderId từ URL
  const getQueryParam = (param) => new URLSearchParams(location.search).get(param);
  const orderId = getQueryParam('id');

  // Lấy dữ liệu đơn hàng theo orderId
  useEffect(() => {
    const fetchOrderData = async () => {
      if (orderId) {
        try {
          const response = await fetch(`https://api.pethaven.website/admin/order/${orderId}`);
          const result = await response.json();

          if (response.ok) {
            setOrderData(result);
            setStatus(result.status);
          } else {
            alert(`Lỗi khi lấy dữ liệu đơn hàng: ${result.message}`);
          }
        } catch (error) {
          alert(`Lỗi kết nối API: ${error}`);
          console.error('Lỗi:', error);
        }
      }
    };

    fetchOrderData();
  }, [orderId]);

  // Xử lý cập nhật trạng thái
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://api.pethaven.website/admin/order/update/${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Cập nhật trạng thái thành công');
        navigate('/OderAdmin');
      } else {
        alert(`Lỗi cập nhật trạng thái: ${result.message}`);
      }
    } catch (error) {
      alert(`Lỗi kết nối API: ${error}`);
      console.error('Lỗi:', error);
    }
  };

  // Lọc trạng thái cho phép dựa trên trạng thái hiện tại
  const getAvailableStatuses = () => {
    return Object.keys(statusFlow).map((key) => {
      const statusLabels = {
        pending: 'Chờ xác nhận',
        prepare: 'Đang chuẩn bị',
        shipping: 'Đang vận chuyển',
        success: 'Thành công',
        cancle: 'Đã hủy',
        return: 'Trả về',
      };

      return {
        value: key,
        label: statusLabels[key], // Hiển thị tiếng Việt cho các trạng thái
        disabled: !statusFlow[status].includes(key), // Disable if not allowed
      };
    });
  };

  return (
    <div className="d-flex">
      <Sidebar />

      <div className="content flex-grow-1">
        <div className="header">
          <h4 className="main-title-adminindex">Sửa Trạng Thái Đơn Hàng</h4>
        </div>

        <div className="container mt-4">
          <form onSubmit={handleSubmit}>
            {orderData && (
              <>
                <div className="mb-3">
                  <label className="form-label">Tên Khách Hàng</label>
                  <input type="text" className="form-control" value={orderData.user_fullname} readOnly />
                </div>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input type="email" className="form-control" value={orderData.user_email} readOnly />
                </div>
                <div className="mb-3">
                  <label className="form-label">Số Điện Thoại</label>
                  <input type="tel" className="form-control" value={orderData.user_phone} readOnly />
                </div>

                <div className="mb-3">
                  <label className="form-label">Tổng Tiền</label>
                  <input type="text" className="form-control" value={`${orderData.total_money}đ`} readOnly />
                </div>

                <div className="mb-3">
                  <label className="form-label">Trạng Thái</label>
                  <select
                    className="form-select"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    {getAvailableStatuses().map(({ value, label, disabled }) => (
                      <option key={value} value={value} disabled={disabled}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Bảng chi tiết đơn hàng */}
                <div className="table-responsive mt-4">
                  <table className="table table-bordered">
                    <thead className="table-primary">
                      <tr>
                        <th>Tên sản phẩm</th>
                        <th>Giá tiền</th>
                        <th>Số lượng</th>
                        <th>Thành tiền</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData.order_details.map((product, index) => (
                        <tr key={index}>
                          <td>{product.product_name}</td>
                          <td>{product.price}đ</td>
                          <td>{product.quantity}</td>
                          <td>{product.total_price.toLocaleString()}đ</td>
                        </tr>
                      ))}
                      <tr>
                        <th>Tổng tiền</th>
                        <td colSpan="3" style={{ textAlign: 'right' }}>
                          {`${orderData.total_money}đ`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <button type="submit" className="btn btn-primary mt-3">
                  Lưu Thay Đổi
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditOrderAdmin;

