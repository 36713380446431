import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import React, { useEffect, useState } from 'react';

// Đăng ký các thành phần cần thiết cho biểu đồ
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Doanh thu dịch vụ và đơn hàng trong 7 ngày gần nhất',
        },
    },
};

// Hàm biểu đồ
export function MyChartday() {
    const [data, setData] = useState({
        labels: [], // Mảng rỗng cho nhãn ngày
        datasets: [
            {
                label: 'Order Revenue',
                data: [], // Dữ liệu doanh thu order
                backgroundColor: 'pink',
            },
            {
                label: 'Service Revenue',
                data: [], // Dữ liệu doanh thu service
                backgroundColor: 'lightblue',
            },
        ],
    });

    // Hàm để fetch dữ liệu doanh thu order trong 7 ngày
    const fetchOrderRevenue = async () => {
        const response = await fetch('https://api.pethaven.website/admin/revenue/order/revenue-last-7-days');
        const orderData = await response.json();
        return orderData.daily_revenue;
    };

    // Hàm để fetch dữ liệu doanh thu service trong 7 ngày
    const fetchServiceRevenue = async () => {
        const response = await fetch('https://api.pethaven.website/admin/revenue/service/revenue-last-7-days');
        const serviceData = await response.json();
        return serviceData.daily_revenue;
    };

    // Sử dụng useEffect để gọi các API khi component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Gọi cả hai API
                const [orderRevenue, serviceRevenue] = await Promise.all([
                    fetchOrderRevenue(),
                    fetchServiceRevenue(),
                ]);

                // Xử lý dữ liệu nhãn và giá trị cho biểu đồ
                const labels = Object.keys(orderRevenue);
                const orderValues = Object.values(orderRevenue).map(Number);
                const serviceValues = Object.values(serviceRevenue).map(Number);

                // Cập nhật dữ liệu vào state
                setData({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Order Revenue',
                            data: orderValues,
                            backgroundColor: 'pink',
                        },
                        {
                            label: 'Service Revenue',
                            data: serviceValues,
                            backgroundColor: 'lightblue',
                        },
                    ],
                });
            } catch (error) {
                console.error('Đã xảy ra lỗi:', error);
            }
        };

        fetchData(); // Gọi hàm để lấy dữ liệu
    }, []);

    return <Bar options={options} data={data} />;
}
