import React from 'react'

const GioiThieu = () => {
  return (
    <>
        <div className="banner-service">
          <div className="banner-service-text">
            <h1 className="main-title">Giới thiệu</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">Trang Chủ</a></li>
                <li className="breadcrumb-item active-pro" aria-current="page">Giới thiệu</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container intro-section">
          <div className="row">
          
            <div className="col-md-6">
              <h1>Trung tâm chăm sóc thú cưng</h1>
              <p>There are many variations of passages of lorem available,
                but the majority have suffered alteration. There are
                many variations of passages of lorem free market to
                available, but the majority have alteration in some
                form, by injected humour, or randomised words.</p>
              <button className="btn-custom">Khám phá</button>
            </div>
            <div className="col-md-6 image-section-gt">
              <img id="intro-image" src="../img/gioithieu1.jpg" alt="Trung tâm chăm sóc thú cưng" />
            </div>
          </div>
        </div>
        <div className="container stats-section">
          <div className="row">
            <div className="col-md-4 custom-description-gioithieu">
              <div className="stat-box blue">
                <div className="stat-icon">
                  <i className="fa-regular fa-comment" style={{color: '#74C0FC'}} />
                </div>
                <div className="stat-value">180</div>
                <div className="stat-text">Đánh giá tích cực</div>
              </div>
            </div>
            <div className="col-md-4 custom-description-gioithieu">
              <div className="stat-box yellow">
                <div className="stat-icon">
                  <img src="../img/doggt1.png " width="50px;" />
                </div>
                <div className="stat-value">68+</div>
                <div className="stat-text">Sản phẩm</div>
              </div>
            </div>
            <div className="col-md-4 custom-description-gioithieu">
              <div className="stat-box green">
                <div className="stat-icon">
                  <img src="../img/usergt2.png" width="50px;" />
                </div>
                <div className="stat-value">28+</div>
                <div className="stat-text">Chuyên gia</div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row my-5">
            {/* Left Side - Testimonials */}
            <div className="col-md-6 bg-primary text-white p-4">
              <h3 className="mb-4 text-center">Khách hàng đánh giá</h3>
              <div className="text-center">
                <div className="testimonial d-inline-block mx-2">
                  <img src="../img/avatar-gt1.jpg" alt="Person 1" className="testimonial-img clickable-img" onclick="toggleCaption('caption1')" />
                </div>
                <div className="testimonial d-inline-block mx-2">
                  <img src="../img/avtar-gt2.jpg" alt="Person 2" className="testimonial-img clickable-img" onclick="toggleCaption('caption2')" />
                </div>
                <div className="testimonial d-inline-block mx-2">
                  <img src="../img/avt-gt3.jpg" alt="Person 3" className="testimonial-img clickable-img" onclick="toggleCaption('caption3')" />
                </div>
              </div>
              <div className="caption-container mt-4">
                <div id="caption1" className="testimonial-caption"><p>"Tôi rất hài lòng với dịch vụ. Sẽ quay lại trong tương lai!"</p>
                  <br /><p style={{color: 'darkkhaki'}}> Ryder</p> </div>
                <div id="caption2" className="testimonial-caption"><p>"Dịch vụ tuyệt vời và chăm sóc thú cưng rất chu đáo."</p>
                  <br /><p style={{color: 'darkkhaki'}}>Nicky</p> </div>
                <div id="caption3" className="testimonial-caption"><p>"Thú cưng của tôi rất thoải mái khi được chăm sóc tại đây!"</p>
                  <br /><p style={{color: 'darkkhaki'}}>Jsol</p></div>
              </div>
            </div>
            {/* Right Side - Images */}
            <div className="col-md-6">
              <div className="row">
                <div className="col-6 mb-4 hover-img-gt">
                  <img src="../img/gioithieu4.jpg" alt="Pet 1" className="right-img" />
                </div>
                <div className="col-6 mb-4 hover-img-gt">
                  <img src="../img/gioithieu5.jpg" alt="Pet 2" className="right-img" />
                </div>
                <div className="col-6 mb-4 hover-img-gt">
                  <img src="../img/gioithieu7.jpg" alt="Pet 3" className="right-img" />
                </div>
                <div className="col-6 mb-4 hover-img-gt">
                  <img src="../img/gioithieu8.jpg" alt="Pet 4" className="right-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container gt-container">
          <div className="row">
            {/* Left Section: Dịch vụ chăm sóc thú cưng */}
            <div className="col-md-6 gt-left-section">
              <h2 className="gt-h2">Dịch vụ chăm sóc thú cưng đáng tin cậy</h2>
              <img src="../img/khachhanggt01.jpg" alt="Customer Image" className="img-fluid rounded-circle mb-3" width={150} />
              <ul className="list-unstyled gt-ul">
                <li>Chứng nhận và đánh giá</li>
                <li>Niềm tin và uy tín</li>
                <li>Chuyên gia chuyên nghiệp</li>
                <li>Thanh toán an toàn</li>
              </ul>
              <div className="progress gt-progress">
                <div className="progress-bar gt-progress-bar" role="progressbar" style={{width: '94%'}} aria-valuenow={94} aria-valuemin={0} aria-valuemax={100}>94%</div>
              </div>
            </div>
            {/* Right Section: Accordion (Dịch vụ và câu hỏi) */}
            <div className="col-md-6 gt-right-section">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Các dịch vụ chăm sóc y tế bao gồm những gì?
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Các dịch vụ chăm sóc y tế thường bao gồm kiểm
                      tra sức khỏe định kỳ, tiêm phòng, xét nghiệm,
                      điều trị bệnh, phẫu thuật, và cung cấp thuốc cho
                      thú cưng của bạn.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Làm thế nào để đặt một dịch vụ chăm sóc hoặc
                      huấn luyện cho thú cưng?
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Bạn có thể đặt dịch vụ chăm sóc hoặc huấn luyện
                      thông qua trang web bằng cách chọn dịch vụ mong
                      muốn và điền vào biểu mẫu đặt lịch hoặc liên hệ
                      trực tiếp với nhân viên qua số điện thoại hoặc
                      email.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Trang web có nhận giao hàng sản phẩm thú cưng
                      không?
                    </button></h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Trang web có thể cung cấp dịch vụ giao hàng
                      sản phẩm thú cưng. Bạn có thể chọn sản phẩm
                      mong muốn, thêm vào giỏ hàng và hoàn tất quá
                      trình đặt hàng. Thông tin về phí vận chuyển
                      và thời gian giao hàng sẽ được cung cấp
                      trong quá trình thanh toán.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      Tôi cần hỗ trợ kỹ thuật, làm thế nào để liên
                      hệ với trung tâm hỗ trợ?
                    </button></h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Bạn có thể liên hệ với trung tâm hỗ trợ
                      thông qua số điện thoại hoặc email được
                      cung cấp trên trang web. Nhân viên hỗ
                      trợ sẽ sẵn lòng giúp bạn giải quyết các
                      vấn đề kỹ thuật hoặc trả lời các câu hỏi
                      liên quan đến trang web và dịch vụ của
                      nó.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="text-center">
            <h2>Gặp gỡ các chuyên gia được chứng nhận</h2>
          </div>
          <div className="row mt-5 justify-content-center">
            {/* Card 1 */}
            <div className="col-lg-4 col-md-6 col-sm-12 text-center mb-4">
              <div className="gt-card position-relative">
                <div className="gt-image-container">
                  <img src="../img/team-01gt.jpg" alt="Thùy Dương" className="rounded-circle img-fluid" />
                </div>
                <h4 className="mt-3">Thùy Dương</h4>
                <p className="text-muted">Nhân viên chăm sóc</p>
                <div className="gt-social-icons">
                  <a href="#" className="btn btn-light rounded-circle"><i className="fab fa-facebook-f" /></a>
                  <a href="#" className="btn btn-light rounded-circle"><i className="fab fa-instagram" /></a>
                  <a href="#" className="btn btn-light rounded-circle"><i className="fab fa-twitter" /></a>
                </div>
              </div>
            </div>
            {/* Card 2 */}
            <div className="col-lg-4 col-md-6 col-sm-12 text-center mb-4">
              <div className="gt-card position-relative">
                <div className="gt-image-container">
                  <img src="../img/team-02gt.jpg" alt="Văn Long" className="rounded-circle img-fluid" />
                </div>
                <h4 className="mt-3">Văn Long</h4>
                <p className="text-muted">Chăm sóc khách hàng</p>
                <div className="gt-social-icons">
                  <a href="#" className="btn btn-light rounded-circle"><i className="fab fa-facebook-f" /></a>
                  <a href="#" className="btn btn-light rounded-circle"><i className="fab fa-instagram" /></a>
                  <a href="#" className="btn btn-light rounded-circle"><i className="fab fa-twitter" /></a>
                </div>
              </div>
            </div>
            {/* Card 3 */}
            <div className="col-lg-4 col-md-6 col-sm-12 text-center mb-4">
              <div className="gt-card position-relative">
                <div className="gt-image-container">
                  <img src="../img/team-03gt.jpg" alt="Linh Min" className="rounded-circle img-fluid" />
                </div>
                <h4 className="mt-3">Linh Min</h4>
                <p className="text-muted">Chăm sóc khách hàng</p>
                <div className="gt-social-icons">
                  <a href="#" className="btn btn-light rounded-circle"><i className="fab fa-facebook-f" /></a>
                  <a href="#" className="btn btn-light rounded-circle"><i className="fab fa-instagram" /></a>
                  <a href="#" className="btn btn-light rounded-circle"><i className="fab fa-twitter" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default GioiThieu
