// src/api/service.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.pethaven.website/api',
});

export const getServices = async () => {
  try {
    const response = await axios.get('https://api.pethaven.website/api/services');
    return response.data.data.data; 
  } catch (error) {
    console.error('Lỗi khi lấy dữ liệu dịch vụ:', error);
    return [];
  }
};

export const getPets = async () => {
  try {
    const response = await axios.get('https://api.pethaven.website/api/pets');
    return response.data; // Điều chỉnh đường dẫn và cấu trúc JSON nếu cần
  } catch (error) {
    console.error('Lỗi khi lấy dữ liệu thú cưng:', error);
    return [];
  }
};

export const bookService = async (bookingData) => {
  try {
    const response = await api.post('/service-booking', bookingData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Lỗi không xác định');
  }
};
