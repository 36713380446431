import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import React, { useEffect, useState } from 'react';

// Đăng ký các thành phần cần thiết cho biểu đồ
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Doanh thu đơn hàng và dịch vụ theo 30 ngày',
        },
    },
};

// Hàm biểu đồ
export function MyChartMonth({ year = 2024, month = 11 }) {
    const [data, setData] = useState({
        labels: Array.from({ length: 30 }, (_, i) => `Ngày ${i + 1}`), // Tạo nhãn cho 30 ngày
        datasets: [
            {
                label: 'Order Revenue',
                data: Array(30).fill(0), // Khởi tạo mảng với 30 ngày, tất cả giá trị là 0 cho order
                backgroundColor: 'rgba(255, 159, 64, 0.7)',
            },
            {
                label: 'Service Revenue',
                data: Array(30).fill(0), // Khởi tạo mảng với 30 ngày, tất cả giá trị là 0 cho service
                backgroundColor: 'rgba(153, 102, 255, 0.7)',
            },
        ],
    });

    // Hàm để fetch dữ liệu doanh thu order theo tháng
    const fetchOrderRevenue = async (year, month) => {
        const response = await fetch(`https://api.pethaven.website/admin/revenue/order/revenuemonth/${year}/${month}`);
        const orderData = await response.json();
        return orderData.daily_revenue;
    };

    // Hàm để fetch dữ liệu doanh thu service theo tháng
    const fetchServiceRevenue = async (year, month) => {
        const response = await fetch(`https://api.pethaven.website/admin/revenue/service/revenuemonth/${year}/${month}`);
        const serviceData = await response.json();
        return serviceData.daily_revenue;
    };

    // Sử dụng useEffect để gọi cả hai API khi component mount hoặc khi year, month thay đổi
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Gọi cả hai API
                const [orderRevenue, serviceRevenue] = await Promise.all([
                    fetchOrderRevenue(year, month),
                    fetchServiceRevenue(year, month),
                ]);

                // Chuyển đổi dữ liệu doanh thu thành mảng 30 ngày
                const orderValues = Array.from({ length: 30 }, (_, i) =>
                    orderRevenue[i + 1] ? Number(orderRevenue[i + 1]) : 0
                );
                const serviceValues = Array.from({ length: 30 }, (_, i) =>
                    serviceRevenue[i + 1] ? Number(serviceRevenue[i + 1]) : 0
                );

                // Cập nhật dữ liệu vào state
                setData((prevData) => ({
                    ...prevData,
                    datasets: [
                        {
                            ...prevData.datasets[0],
                            data: orderValues, // Dữ liệu order
                        },
                        {
                            ...prevData.datasets[1],
                            data: serviceValues, // Dữ liệu service
                        },
                    ],
                }));
            } catch (error) {
                console.error('Đã xảy ra lỗi:', error);
            }
        };

        fetchData();
    }, [year, month]); // Sử dụng year, month làm dependencies để cập nhật lại dữ liệu khi thay đổi

    return <Bar options={options} data={data} />;
}
