import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getNews } from '../../api/news';
const News = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchServices = async () => {
          setLoading(true);
          const data = await getNews(1, '', 9, ''); // Gọi hàm getService với tham số mặc định
          console.log(data);

          if (data ) {
            setNews(data.data); // Lưu dữ liệu dịch vụ vào state
          } else {
            console.error('Lỗi khi lấy danh sách dịch vụ');
          }
          setLoading(false);
        };
    
        fetchServices();
      }, []);
      if (loading) return  <div className="loading-container">
      <svg viewBox="25 25 50 50" className="spinner">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
    </div>; 
  return (
    <>
        <div>
        <div className="banner-service">
          <div className="banner-service-text">
            <h1 className="main-title">Tin tức</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link href="#">Trang Chủ</Link></li>
                <li className="breadcrumb-item active-pro" aria-current="page">Tin tức</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row justify-content-around">
            <div className="col-lg-7 col-md-7 margin-service-edit">
            {
                news.map((service) => (
                    <div className="card-service shadow-sm p-3 bg-white rounded" key={service.id}>
                    <img src={`../img1/${service.detail}`} className="post-image-tt mb-3" alt="Dog playing piano" />
                    <div className="card-body card-tintuc">
                      <p className="news-meta">
                        <i className="fa-solid fa-tag" style={{color: '#008BA7'}} /> Health Care 
                        <i className="fa-solid fa-comments ml-3" style={{color: '#008BA7'}} /> 0 Bình luận
                      </p>
                      <h2 className="card-title">{service.title}</h2>
                      <p className="card-text">{service.description}</p>
                      <Link to={`/NewsDesciption/${service.id}`} className="read-more-btn">Đọc thêm</Link>
                    </div>
                  </div>
                ))
              }
             
              
            </div>
            <div className="col-lg-4 col-md-5">
              <div className="mb-4 sidebar">
                <h5 className="text-muted">Bài viết mới</h5>
                <ul className="list-group">
                  <li className="list-group-item d-flex align-items-center">
                    <img src="../img/blog-05-150x150.jpg" alt="Thumbnail" className="sidebar-image" />
                    <div>
                      <a href="#">Watch how its heart out while playing piano</a>
                      <br />
                    </div>
                  </li>
                  <li className="list-group-item d-flex align-items-center">
                    <img src="../img/blog-05-150x150.jpg" alt="Thumbnail" className="sidebar-image" />
                    <div>
                    <Link href="#">Tips and tricks: Puppy's First Grooming</Link>
                      <br />
                    </div>
                  </li>
                  <li className="list-group-item d-flex align-items-center">
                    <img src="../img/blog-05-150x150.jpg" alt="Thumbnail" className="sidebar-image" />
                    <div>
                    <Link href="#">Tips and tricks: Puppy's First Grooming</Link>
                      <br />
                    </div>
                  </li>
                  <li className="list-group-item d-flex align-items-center">
                    <img src="../img/blog-05-150x150.jpg" alt="Thumbnail" className="sidebar-image" />
                    <div>
                    <Link href="#">Tips and tricks: Puppy's First Grooming</Link>
                      <br />
                    </div>
                  </li>
                  <li className="list-group-item d-flex align-items-center">
                    <img src="../img/blog-05-150x150.jpg" alt="Thumbnail" className="sidebar-image" />
                    <div>
                      <Link href="#">Tips and tricks: Puppy's First Grooming</Link>
                      <br />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default News
