// context/CartContext.js
import React, { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product, quantity = 1) => { 
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);

      // Kiểm tra số lượng tồn kho trước khi thêm vào giỏ hàng
      if (existingProduct) {
        if (existingProduct.quantity + quantity > product.stock_quantity) {
          alert("Số lượng sản phẩm trong giỏ không được vượt quá số lượng tồn kho.");
          return prevCart; // Không thêm vào giỏ nếu vượt quá tồn kho
        }
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      }

      // Kiểm tra tồn kho cho sản phẩm mới
      if (quantity > product.stock_quantity) {
        alert("Số lượng sản phẩm không được vượt quá số lượng tồn kho.");
        return prevCart;
      }

      return [...prevCart, { ...product, quantity }];
    });
  };
  
  const updateQuantity = (productId, quantity) => {
    setCart((prevCart) =>
      prevCart.map((item) => {
        if (item.id === productId) {
          if (quantity > item.stock_quantity) {
            alert("Số lượng không thể vượt quá số lượng tồn kho.");
            return item; // Giữ nguyên nếu vượt quá tồn kho
          }
          return { ...item, quantity };
        }
        return item;
      })
    );
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, updateQuantity, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};
