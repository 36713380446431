import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Personal = () => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    gender: '',
    birthdate: '',
    img: '',
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false); // Trạng thái hiển thị dropdown

  // Lấy thông tin user khi component được mount
  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('user'))?.id;

    if (userId) {
      const getUserProfile = async () => {
        try {
          const response = await fetch(`https://api.pethaven.website/api/user/profile/${userId}`);
          const profileData = await response.json();
          setUserData(profileData);
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      };
      getUserProfile();
    } else {
      console.error("User ID is missing in localStorage.");
    }
  }, []);

  // Toggle dropdown "Đơn hàng của tôi"
  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  // Submit form cập nhật user
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = JSON.parse(localStorage.getItem('user'))?.id;

    if (!userId) {
      console.error("User ID is missing or undefined.");
      return;
    }

    const formData = new FormData();
    formData.append('name', userData.name);
    formData.append('email', userData.email);
    formData.append('phone', userData.phone);
    formData.append('gender', userData.gender);
    formData.append('birthdate', userData.birthdate);

    if (selectedImage) {
      formData.append('img', selectedImage);
    }

    try {
      const response = await fetch(`https://api.pethaven.website/api/user/profile/${userId}`, {
        method: 'POST',
        headers: {
          "X-HTTP-Method-Override": "PUT",
        },
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response text:', errorText);
        throw new Error(`Failed to update user profile: ${response.status} ${response.statusText}`);
      }

      const result = await response.json();

      if (result.user) {
        setUserData(result.user);
        localStorage.setItem('user', JSON.stringify(result.user));
        alert(result.message || 'User information updated successfully');
      } else {
        alert(result.message || 'User information updated successfully');
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
      alert('An error occurred while updating user information.');
    }
  };

  // Handle thay đổi giá trị input
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'img' && files) {
      setSelectedImage(files[0]);
      setUserData((prevData) => ({
        ...prevData,
        img: URL.createObjectURL(files[0]),
      }));
    } else {
      setUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  return (
    <div className="container center-personel">
      <div className="row profile-container">
        {/* Sidebar */}
        <div className="profile-sidebar">
          <img
            id="profile-image"
            src={`https://api.pethaven.website/images/user/${userData.img}`}
            alt="Profile Picture"
            className="profile-picture"
          />
          <div className="user-info-personal">
            <h4>{userData.name || 'Người dùng'}</h4>
            <p>Đang hoạt động</p>
            <Link to="/BookingHistory">Lịch sử đặt lịch</Link>
            <a href="#" onClick={toggleDropdown}>
              Đơn hàng của tôi
            </a>
            {dropdownVisible && (
              <div id="order-status-dropdown">
                <ul>
                <li><Link to="/OrderHistory">Chờ xác nhận</Link></li>
                  <li><Link to="/OderHistoryXn">Đã xác nhận</Link></li>
                  <li><Link to="/OderHistoryShip">Giao hàng</Link></li>
                  <li><Link to="/OderHistoryDn">Hoàn thành</Link></li>
                  <li><Link to="/OderHistoryHuy">Đã hủy</Link></li>
                  <li><Link to="/OderHistoryReturn">Hoàn trả</Link></li>
                </ul>
              </div>
            )}
          </div>
        </div>

        {/* Form thông tin */}
        <div className="profile-info">
          <h4>Thông Tin Cá Nhân</h4>
          <form id="profile-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Tên</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={userData.name || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={userData.email || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Số điện thoại</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                value={userData.phone || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Giới tính</label>
              <div>
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="male"
                  checked={userData.gender === 'male'}
                  onChange={handleChange}
                /> Nam
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="female"
                  className="ml-3"
                  checked={userData.gender === 'female'}
                  onChange={handleChange}
                /> Nữ
                <input
                  type="radio"
                  name="gender"
                  id="other"
                  value="other"
                  className="ml-3"
                  checked={userData.gender === 'other'}
                  onChange={handleChange}
                /> Khác
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="birthdate">Ngày sinh</label>
              <input
                type="date"
                className="form-control"
                id="birthdate"
                name="birthdate"
                value={userData.birthdate || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="img">Ảnh đại diện</label>
              <input
                type="file"
                className="form-control"
                id="img"
                name="img"
                accept="image/*"
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="btn btn-danger">Lưu</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Personal;
