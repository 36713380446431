import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchComments, deleteComment } from '../../services/Admin/CommentService'; // Hàm lấy dữ liệu bình luận từ service
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin'; // Import UserInfo component

const CommentAdmin = () => {
  const [comments, setComments] = useState([]); // State cho dữ liệu bình luận
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const getComments = async () => {
    setLoading(true);
    try {
      const { data, last_page } = await fetchComments(search, page, perPage); // Gọi hàm lấy dữ liệu bình luận
      setComments(data);
      setTotalPages(last_page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getComments();
  }, [search, page, perPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); // Reset về trang đầu khi tìm kiếm
    getComments();
  };

  const handleDelete = async (id) => {
    if (window.confirm('Bạn có chắc chắn muốn xóa bình luận này?')) {
      try {
        await deleteComment(id); // Gọi hàm xóa bình luận
        getComments(); // Tải lại danh sách bình luận
      } catch (error) {
        console.error('Error deleting comment:', error);
      }
    }
  };

  if (loading) return  <div className=" loading-container">
  <svg viewBox="25 25 50 50" className="spinner">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
</div>;

  return (
    <div className="d-flex">
      <Sidebar />

      <div className="content flex-grow-1">
        <div className="header" style={{ marginBottom: '30px' }}>
          <div className="text-left">
            <h4 className="main-title-adminindex">Danh sách bình luận</h4>
          </div>
          <UserInfo />
        </div>

        <div className="search-bar-container">
          <div className="search-bar">
            <input
              className="form-control form-addsp"
              placeholder="Tìm bình luận"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className="btn btn-dark" onClick={handleSearch}>
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>

        <div className="table-container">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>STT</th>
                <th>Tên Người Dùng</th>
                <th>Tên Sản Phẩm</th>
                <th>Nội Dung Bình Luận</th>
                <th>Rating</th>
                <th>Hành Động</th>
              </tr>
            </thead>
            <tbody>
              {comments.map((comment, index) => (
                <tr key={comment.id}>
                  <td>{index + 1}</td>
                  <td>{comment.user_name}</td> {/* Lấy tên người dùng */}
                  <td>{comment.product_name}</td> {/* Lấy tên sản phẩm */}
                  <td>{comment.content}</td>
                  <td>
                    {Array.from({ length: comment.rating }, (_, i) => (
                      <span key={i}>★</span>
                    ))}
                  </td>

                  <td>
                    <i className="fas fa-trash-alt text-danger" onClick={() => handleDelete(comment.id)}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                  Previous
                </button>
              </li>
              <li className="page-item">
                <span className="page-link">Page {page} of {totalPages}</span>
              </li>
              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default CommentAdmin;
