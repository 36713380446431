import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchCategoriesNew, deleteCategory } from '../../services/Admin/NewService'; // Thêm hàm lấy dữ liệu news từ service
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin'; // Import UserInfo component

const CategoryNewsAdmin = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const getCategories = async () => {
    setLoading(true);
    try {
      const { data, last_page } = await fetchCategoriesNew(search, page, perPage); // Gọi hàm fetchNews từ service
      setCategories(data);
      setTotalPages(last_page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching news:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, [search, page, perPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); // Reset về trang đầu khi tìm kiếm
    getCategories();
  };
  const handleDelete = async (id) => {
    if (window.confirm('Bạn có chắc chắn muốn xóa danh mục này?')) {
      try {
        await deleteCategory(id); // Gọi hàm xóa danh mục
        getCategories(); // Tải lại danh sách danh mục
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          // Hiển thị thông báo lỗi từ API nếu có
          alert(error.response.data.message);
        } else {
          // Hiển thị thông báo lỗi chung nếu không có phản hồi từ API
          alert('Đã xảy ra lỗi khi xóa danh mục.');
        }
      }
    }
  };

  if (loading) return  <div className="loading-container">
  <svg viewBox="25 25 50 50" className="spinner">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
</div>;
  if (categories.length === 0) return <div>Không có thể loại tin tức nào.</div>;

  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="content flex-grow-1">
          <div className="header">
            <div className="text-left">
              <h4 className="main-title-adminindex">Danh sách thể loại tin tức</h4>
            </div>
            <UserInfo />
          </div>

          <div className="search-bar-container">
            <div className="search-bar">
              <input
                className="form-control form-addsp"
                placeholder="Tìm thể loại tin tức"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button className="btn btn-dark" onClick={handleSearch}>
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>

          <form className="form-inline text-left">
            <Link to="/add-categorynew">
              <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#addCategoryModal">
                Thêm thể loại
              </button>
            </Link>
          </form>

          <div className="table-container">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Thể Loại Tin Tức</th>
                  <th>Hành động</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <Link to={`/edit-categorynew/?id=${item.id}`} onClick={() => console.log(`Editing product ${item.id}`)}>
                        <i className="fas fa-edit text-success" />
                      </Link>
                      |
                      <a href="#">
                        <i className="fas fa-trash-alt text-danger" onClick={() => handleDelete(item.id)}></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                    Previous
                  </button>
                </li>
                <li className="page-item">
                  <span className="page-link">Page {page} of {totalPages}</span>
                </li>
                <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryNewsAdmin;
