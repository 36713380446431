import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SliderBar';
import UserInfo from '../../components/UserInfoAdmin';

const AddProducts = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [productData, setProductData] = useState({
        name: '',
        description: '',
        category_id: '',
        price: '',
        instock: '',
        sale_price: '',
        image: null,
        imageUrl: null
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getQueryParam = (param) => {
        return new URLSearchParams(location.search).get(param);
    };

    const id = getQueryParam('id');

    useEffect(() => {
        const fetchProductData = async () => {
            if (id) {
                try {
                    const response = await fetch(`https://api.pethaven.website/admin/product/${id}`);
                    const result = await response.json();

                    if (response.ok) {
                        setProductData({
                            ...result,
                            imageUrl: result.image ? result.image : null,
                            category_id: result.category_id || ''
                        });
                    } else {
                        alert('Lỗi khi lấy dữ liệu sản phẩm: ' + result.message);
                    }
                } catch (error) {
                    alert('Lỗi kết nối API: ' + error);
                    console.error('Lỗi:', error);
                }
            }
        };

        fetchProductData();
    }, [id]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://api.pethaven.website/admin/product-category');
                const result = await response.json();

                if (response.ok) {
                    setCategories(result);
                } else {
                    console.error('Lỗi khi lấy danh mục:', result.message);
                }
            } catch (error) {
                console.error('Lỗi kết nối API:', error);
            }
        };

        fetchCategories();
    }, []);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setProductData({
            ...productData,
            [id]: value
        });
    };

    const handleFileChange = (e) => {
        setProductData({
            ...productData,
            image: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!productData.name || productData.price <= 0 || !productData.category_id) {
            alert('Vui lòng điền đầy đủ thông tin và giá phải lớn hơn 0.');
            return;
        }

        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('name', productData.name);
        formData.append('description', productData.description);
        formData.append('category_id', productData.category_id);
        formData.append('price', productData.price);
        formData.append('sale_price', productData.sale_price);
        if (productData.image) {
            formData.append('image', productData.image);
        }

        try {
            const url = `https://api.pethaven.website/admin/product/${id ? `update/${id}` : 'add'}`;
            const options = {
                method: id ? 'POST' : 'POST',
                headers: id
                    ? {
                        "X-HTTP-Method-Override": "PUT",
                    }
                    : {},
                body: formData,
                mode: 'cors'
            };

            const response = await fetch(url, options);
            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('application/json')) {
                const text = await response.text();
                console.error('Server trả về kết quả không phải JSON:', text);
                alert('Lỗi: Server trả về không phải JSON. Chi tiết: ' + text);
                return;
            }

            const result = await response.json();
            if (response.ok) {
                alert(result.message);
                navigate('/ProductAdmin');
            } else {
                alert('Đã xảy ra lỗi: ' + result.message);
            }
        } catch (error) {
            alert('Lỗi kết nối API: ' + error);
            console.log('Lỗi:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="d-flex">
                <Sidebar />
                <div className="content flex-grow-1">
                    <div className="header"></div>
                    <div className="container mt-4">
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Tên Sản Phẩm</label>
                                <input
                                    type="text"
                                    className="form-control form-addsp"
                                    id="name"
                                    placeholder="Nhập tên sản phẩm"
                                    value={productData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            {productData.imageUrl && (
                                <div className="mb-3">
                                    <label className="form-label">Ảnh Hiện Tại</label>
                                    <img
                                        src={`https://api.pethaven.website/images/products/${productData.imageUrl}`}
                                        alt="Current Product"
                                        style={{ width: '200px', height: 'auto' }}
                                    />
                                    <p>Tên hình ảnh: {productData.imageUrl}</p>
                                </div>
                            )}

                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Hình Ảnh</label>
                                <input
                                    type="file"
                                    className="form-control form-addsp"
                                    id="image"
                                    onChange={handleFileChange}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="category_id" className="form-label">Danh Mục</label>
                                <select
                                    className="form-select form-addsp"
                                    id="category_id"
                                    value={productData.category_id}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Chọn danh mục</option>
                                    {categories && categories.length > 0 ? (
                                        categories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">Không có danh mục</option>
                                    )}
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="price" className="form-label">Giá</label>
                                <input
                                    type="number"
                                    className="form-control form-addsp"
                                    id="price"
                                    placeholder="Nhập giá sản phẩm"
                                    value={productData.price}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="sale_price" className="form-label">Giá Giảm</label>
                                <input
                                    type="number"
                                    className="form-control form-addsp"
                                    id="sale_price"
                                    placeholder="Nhập giá giảm (nếu có)"
                                    value={productData.sale_price}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Mô Tả</label>
                                <textarea
                                    className="form-control form-addsp"
                                    id="description"
                                    rows="3"
                                    value={productData.description}
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>



                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                {isSubmitting ? 'Đang xử lý...' : (id ? 'Cập Nhật Sản Phẩm' : 'Thêm Sản Phẩm')}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddProducts;
