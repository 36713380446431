import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component

const EditServiceBooking = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState(null);
  const [status, setStatus] = useState('');
  const allStatuses = ['pending', 'confirmed', 'canceled', 'success']; // Tất cả trạng thái

  // Lấy bookingId từ URL
  const getQueryParam = (param) => new URLSearchParams(location.search).get(param);
  const bookingId = getQueryParam('id');

  // Định nghĩa quy tắc chuyển đổi trạng thái
  const statusFlow = {
    pending: ['confirmed', 'canceled'], // Chờ xác nhận -> Đã xác nhận, Đã hủy
    confirmed: ['success', 'canceled'], // Đã xác nhận -> Đã thành công, Đã hủy
    success: [], // Đã thành công -> Không thể thay đổi
    canceled: [], // Đã hủy -> Không thể thay đổi
  };

  useEffect(() => {
    const fetchBookingData = async () => {
      if (bookingId) {
        try {
          const response = await fetch(`https://api.pethaven.website/admin/servicebooking/${bookingId}`);
          const result = await response.json();

          if (response.ok) {
            setBookingData(result);
            setStatus(result.status); // Gán trạng thái hiện tại
          } else {
            alert(`Lỗi khi lấy dữ liệu đặt lịch: ${result.message}`);
          }
        } catch (error) {
          alert(`Lỗi kết nối API: ${error}`);
          console.error('Lỗi:', error);
        }
      }
    };

    fetchBookingData();
  }, [bookingId]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://api.pethaven.website/admin/servicebooking/update/${bookingId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Cập nhật trạng thái thành công');
        navigate('/ServiceBookingAdmin');
      } else {
        alert(`Lỗi cập nhật trạng thái: ${result.message}`);
      }
    } catch (error) {
      alert(`Lỗi kết nối API: ${error}`);
      console.error('Lỗi:', error);
    }
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="content flex-grow-1">
        <div className="header">
          <h4 className="main-title-adminindex">Sửa Đặt Lịch Dịch Vụ</h4>
        </div>
        <div className="container mt-4">
          <form onSubmit={handleSubmit}>
            {bookingData && (
              <>
                <div className="mb-3">
                  <label className="form-label">Thú Cưng</label>
                  <input type="text" className="form-control" value={bookingData.pet_name} disabled />
                </div>
                <div className="mb-3">
                  <label className="form-label">Dịch Vụ</label>
                  <input type="text" className="form-control" value={bookingData.service_name} disabled />
                </div>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input type="email" className="form-control" value={bookingData.user_email} disabled />
                </div>
                <div className="mb-3">
                  <label className="form-label">Số Điện Thoại</label>
                  <input type="tel" className="form-control" value={bookingData.user_phone} disabled />
                </div>
                <div className="mb-3">
                  <label className="form-label">Ngày</label>
                  <input type="text" className="form-control" value={bookingData.booking_date} disabled />
                </div>
                <div className="mb-3">
                  <label className="form-label">Giá</label>
                  <input type="text" className="form-control" value={bookingData.total_price} disabled />
                </div>
                <div className="mb-3">
                  <label className="form-label">Trạng Thái</label>
                  <select className="form-select" value={status} onChange={handleStatusChange}>
                    {allStatuses.map((statusOption) => (
                      <option
                        key={statusOption}
                        value={statusOption}
                        disabled={
                          statusFlow[bookingData.status]?.includes(statusOption) === false &&
                          bookingData.status !== statusOption
                        }
                      >
                        {statusOption === 'confirmed' && 'Đã xác nhận'}
                        {statusOption === 'pending' && 'Chờ xác nhận'}
                        {statusOption === 'canceled' && 'Đã hủy'}
                        {statusOption === 'success' && 'Đã thành công'}
                      </option>
                    ))}
                  </select>
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                  Lưu Thay Đổi
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditServiceBooking;
