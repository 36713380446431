import axios from 'axios';

// Định nghĩa URL cơ sở
const API_BASE_URL = 'https://api.pethaven.website/admin'; // Thay đổi URL này cho phù hợp

// Lấy thông tin Dashboard


// Lấy danh sách sản phẩm
export const fetchServices = async (search = '', page = 1, perPage = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/service`, {
            params: { search, page, perPage },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching services:', error);
        throw error;
    }
};

export const deleteService = async (serviceId) => {
    const response = await axios.delete(`${API_BASE_URL}/service/delete/${serviceId}`);
    return response.data; // Bạn có thể trả về dữ liệu phản hồi nếu cần
};
export const deleteCategory = async (categoryId) => {
    const response = await axios.delete(`${API_BASE_URL}/category/delete/${categoryId}`);
    return response.data; // Bạn có thể trả về dữ liệu phản hồi nếu cần
};
// Lấy danh sách danh mục
export const fetchBookings = async (
    search = '',
    page = 1,
    perPage = 10,
    fromDate = '',
    toDate = ''

) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/servicebooking`, {
            params: { search, page, perPage, from_date: fromDate, to_date: toDate },
            withCredentials: true, // Đảm bảo gửi cookie nếu cần
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching bookings:', error);
        throw error;
    }
};
export const updateBookingStatus = async (id, status) => {
    return await axios.put(` ${API_BASE_URL}/servicebooking/update/${id}`, { status });
};

// Lấy danh sách người dùng
export const fetchUsers = async () => {
    const response = await axios.get(`${API_BASE_URL}/user`);
    return response.data;
};
export const fetchWarehouseItems = async (search = '', page = 1, perPage = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/stockin`, {
            params: { search, page, perPage },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching services:', error);
        throw error;
    }


};
export const deleteWarehouseItem = async (StockinId) => {
    const response = await axios.delete(`${API_BASE_URL}/stockin/delete/${StockinId}`);
    return response.data; // Bạn có thể trả về dữ liệu phản hồi nếu cần
};