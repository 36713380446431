import axios from 'axios';

// Định nghĩa URL cơ sở
const API_BASE_URL = 'https://api.pethaven.website/admin'; // Thay đổi URL này cho phù hợp

// Lấy thông tin Dashboard
export const fetchDashboardData = async () => {
    const response = await axios.get(`${API_BASE_URL}`);
    return response.data;
};

// Lấy danh sách sản phẩm
export const fetchProducts = async (search = '', page = 1, perPage = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/product`, {
            params: { search, page, perPage },
            withCredentials: true,
            
        });
        return response.data;

    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const deleteProduct = async (productId) => {
    const response = await axios.delete(`${API_BASE_URL}/product/delete/${productId}`);
    return response.data; // Bạn có thể trả về dữ liệu phản hồi nếu cần
};
export const deleteCategory = async (categoryId) => {
    const response = await axios.delete(`${API_BASE_URL}/category/delete/${categoryId}`);
    return response.data; // Bạn có thể trả về dữ liệu phản hồi nếu cần
};
// Lấy danh sách danh mục
export const fetchCategories = async (search = '', page = 1, perPage = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/category`, {
            params: { search, page, perPage },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};
// Lấy danh sách người dùng
export const fetchUsers = async () => {
    const response = await axios.get(`${API_BASE_URL}/user`);
    return response.data;
};
