import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dash } from "./DashTotal";
import axios from "axios";
import Sidebar from '../../components/SliderBar';
import UserInfo from '../../components/UserInfoAdmin';

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    soDonHang: 0,
    soSanPham: 0,
    soKhachHang: 0,
    doanhThu: 0,
    dsDH: [],
    dsBL: []
  });

  const [productData, setProductData] = useState([]);

  // Fetch dashboard data
  useEffect(() => {
    axios.get("https://api.pethaven.website/admin/dashboard")
      .then(response => {
        console.log('dữ liệu dash board', response.data);
        setDashboardData(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the dashboard data!", error);
      });
  }, []);

  // Fetch top-selling products data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.pethaven.website/admin/report/product');
        setProductData(response.data.top_selling_products || []);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4">
            <div className="header" style={{ marginBottom: '30px' }}>
              <div className="text-left">
                <h4 className="main-title-adminindex">Dashboard</h4>
              </div>
              <UserInfo />
            </div>

            {/* Stats Cards */}
            <div className="row">
              <div className="col-md-3">
                <div className="card text-center stats-card">
                  <div className="card-body">
                    <h5>{dashboardData.soDonHang} Orders</h5>
                    <span>📦</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card text-center stats-card">
                  <div className="card-body">
                    <h5>{dashboardData.soSanPham} Products</h5>
                    <span>🛒</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card text-center stats-card">
                  <div className="card-body">
                    <h5>{dashboardData.soKhachHang} Customers</h5>
                    <span>👥</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card text-center stats-card">
                  <div className="card-body">
                    <h5>{dashboardData.soDichVu} Service</h5>
                    <span>🛠️</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Recent Orders */}
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">Danh sách khách hàng hủy đơn</div>
                  <ul className="list-group list-group-flush">
                    {dashboardData.dsDH.map((order, index) => (
                      <li key={index} className="list-group-item">
                        {order.user_fullname} - {order.order_count}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Recent Ratings */}
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">Danh sách bình luận gần đây</div>
                  <ul className="list-group list-group-flush">
                    {dashboardData.dsBL.map((comment, index) => (
                      <li key={index} className="list-group-item">
                        {comment.product_name} -
                        <span className="rating-stars">
                          {Array.from({ length: comment.rating }, (_, i) => (
                            <span key={i}>★</span>
                          ))}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Top Selling Products Section */}
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="card small-card">
                  <div className="card-header"><h4>Danh sách sản phẩm bán chạy</h4></div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="table-light">
                          <tr>
                            <th>STT</th>
                            <th>Tên sản phẩm</th>
                            <th>Số lượng đã bán</th>
                            <th>Doanh thu (VND)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productData.map((product, index) => (
                            <tr key={product.product_id}>
                              <td>{index + 1}</td>
                              <td>{product.name}</td>
                              <td>{product.total_quantity}</td>
                              <td>{product.total_revenue.toLocaleString()} VND</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Additional Dashboard Section */}
            <div className="row mt-4">
              <div className="col-md-8 offset-md-2">
                <Dash />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
