import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin';
const WarehouseEntry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id'); // Lấy ID từ query string
  console.log(id);

  const [warehouseData, setWarehouseData] = useState({
    productName: '',
    productId: '', // Thêm field productId
    quantity: 0,
    stockin_date: '',
  });
  const [productOptions, setProductOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.pethaven.website/admin/get-product');
        const result = await response.json();

        if (response.ok) {
          setProductOptions(result.products);
        } else {
          console.error('Lỗi khi lấy danh sách sản phẩm:', result.message);
        }
      } catch (error) {
        console.error('Lỗi kết nối API:', error);
      }
    };

    const getStockinById = async (id) => {
      try {
        const response = await fetch(`https://api.pethaven.website/admin/stockin/${id}`);
        const result = await response.json();

        if (response.ok) {
          console.log('reponse:', response.json);
          setWarehouseData(result);
        } else {
          alert('Lỗi khi lấy thông tin nhập kho: ' + result.message);
        }
      } catch (error) {
        console.error('Lỗi kết nối API:', error);
      }
    };

    fetchProducts();
    if (id) {
      getStockinById(id);
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    // Chuyển đổi quantity từ chuỗi thành số nguyên
    setWarehouseData({
      ...warehouseData,
      [id]: id === 'quantity' ? parseInt(value, 10) : value
    });
  };

  const handleProductChange = (e) => {
    const selectedProduct = productOptions.find(product => product.name === e.target.value);
    setWarehouseData({
      ...warehouseData,
      productName: e.target.value,
      productId: selectedProduct ? selectedProduct.id : '', // Lưu productId
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = id
        ? await fetch(`https://api.pethaven.website/admin/stockin/update/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(warehouseData),
        })
        : await fetch('https://api.pethaven.website/admin/stockin/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(warehouseData),
        });

      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        navigate('/Warehouse');
      } else {
        alert('Đã xảy ra lỗi: ' + result.message);
      }
    } catch (error) {
      alert('Lỗi kết nối API: ' + error);
      console.log('Lỗi:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="d-flex">
      <Sidebar />

      <div className="content flex-grow-1">
        <div className="header">
          <div className="text-left">
            <h4 className="main-title-adminindex">{id ? 'Cập Nhật Nhập Kho' : 'Thêm Nhập Kho'}</h4>
          </div>
          <UserInfo />
        </div>

        <div className="container mt-4">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="productName" className="form-label">Chọn Sản Phẩm</label>
              <select
                id="productName"
                className="form-control form-addsp"
                value={warehouseData.productName}
                onChange={handleProductChange} // Sử dụng hàm mới
                required
              >
                <option value="">Chọn sản phẩm</option>
                {productOptions.map((product) => (
                  <option key={product.id} value={product.name}>
                    {product.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="quantity" className="form-label">Số Lượng</label>
              <input
                type="number"
                className="form-control form-addsp"
                id="quantity"
                value={warehouseData.quantity}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="stockin_date" className="form-label">Ngày Nhập Kho</label>
              <input
                type="date"
                className="form-control form-addsp"
                id="stockin_date"
                value={warehouseData.stockin_date}
                onChange={handleInputChange}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? 'Đang xử lý...' : (id ? 'Cập Nhật' : 'Nhập Kho')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WarehouseEntry;
