import React, { useEffect, useState } from 'react';
import { fetchServices, deleteService } from '../../services/Admin/ServicesService'; // Đảm bảo bạn có hàm fetchServices và deleteService trong ServiceService
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin'; // Import UserInfo component
const ServiceAdmin = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate(); // Sử dụng hook useNavigate

  const getServices = async () => {
    setLoading(true);
    try {
      const { data, last_page } = await fetchServices(search, page, perPage);
      setServices(data);
      setTotalPages(last_page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching services:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getServices();
  }, [search, page, perPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); // Reset về trang đầu tiên khi tìm kiếm
    getServices();
  };

  const handleDelete = async (id) => {
    if (window.confirm('Bạn có chắc chắn muốn xóa dịch vụ này?')) {
      try {
        await deleteService(id); // Gọi hàm xóa dịch vụ
        getServices(); // Tải lại danh sách dịch vụ
      } catch (error) {
        console.error('Error deleting service:', error);
      }
    }
  };

  if (loading) return  <div className="loading-container">
  <svg viewBox="25 25 50 50" className="spinner">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
</div>;
  if (services.length === 0) return <div>Không có dịch vụ nào.</div>;

  return (
    <div className="d-flex">

      <Sidebar /> {/* Sử dụng Sidebar component */}

      <div className="content flex-grow-1">
        <div className="header">
          <div className="text-left">
            <h4 className="main-title-adminindex"> Quản lý sản phẩm</h4>
          </div>

          <UserInfo />

        </div>
        <div className="search-bar-container">
          <div className="search-bar">
            <input
              className="form-control form-addsp"
              placeholder="Tìm tên dịch vụ"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className="btn btn-dark" onClick={handleSearch}>
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <form className="form-inline text-left">
          <Link to="/add-service">
            <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#addServiceModal">
              Thêm dịch vụ
            </button>
          </Link>
        </form>
        <div className="table-container">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>STT</th>
                <th>Hình ảnh</th>
                <th>Tên dịch vụ</th>
                <th>Giá</th>
                <th>Mô tả</th>
                <th>Sửa</th>
                <th>Xóa</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service, index) => (
                <tr key={service.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img alt={`Product Image ${index + 1}`} height={50} src={`https://api.pethaven.website/images/services/${service.img}`} width={50} />
                  </td>
                  <td>{service.name}</td>
                  <td>{service.price} vnd</td>
                  <td>{service.description}</td> {/* Cập nhật để lấy tên danh mục */}
                  <td>
                    <Link to={`/edit-service/?id=${service.id}`} onClick={() => console.log(`Editing service ${service.id}`)}>
                      <i className="fas fa-edit text-success" />
                    </Link>
                  </td>
                  <td>
                    <i className="fas fa-trash-alt text-danger" onClick={() => handleDelete(service.id)}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination Controls */}
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                  Previous
                </button>
              </li>
              <li className="page-item">
                <span className="page-link">Page {page} of {totalPages}</span>
              </li>
              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default ServiceAdmin;
