import axios from "axios";

const API_URL = 'https://api.pethaven.website/api';

// Hàm đăng nhập
export const login = async (body) => {
    try {
        const response = await axios.post(`${API_URL}/login`, body);
        return response.data;
    } catch (error) {
      
        if (error.response) {
            return error.response.data; // Trả về lỗi từ server
        } else {
            return { success: false, message: 'Lỗi mạng hoặc server không phản hồi' };
        }
    }
};

// Hàm quên mật khẩu - Gửi yêu cầu OTP qua email
export const forgotPassword = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/forgot-password`, { email });
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data; // Trả về lỗi từ server
        } else {
            return { success: false, message: 'Lỗi mạng hoặc server không phản hồi' };
        }
    }
};

// Hàm xác minh OTP
export const verifyOtp = async (data) => {
    try {
      const response = await fetch('https://api.pethaven.website/api/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return await response.json();
    } catch (error) {
      console.error('Lỗi khi xác thực OTP:', error);
      return { success: false, message: 'Lỗi khi xác thực OTP' };
    }
  };
  

// Hàm đặt lại mật khẩu
export const resetPassword = async ({ email, otp, password, password_confirmation }) => {
    try {
      const response = await axios.post('https://api.pethaven.website/api/reset-password', {
        email,
        otp,
        password,
        password_confirmation
      });
      return response.data;
    } catch (error) {
      console.error('Reset password error:', error.response?.data || error.message);
      return { success: false, message: 'Có lỗi xảy ra. Vui lòng thử lại!' };
    }
  };
  


export const register = async (body) => {
    try {
        const response = await axios.post(`${API_URL}/register`, body);
        return response.data;
    } catch (error) {
        console.error('Error registering: ', error);
        throw error;
    }
}

export const logout = () => {
    localStorage.removeItem('user');
};

export const loginWithGoogle = async (token) => {
  try {
    const response = await axios.post('http://127.0.0.1:8000/api/auth/google/callback', {
      token,
    });

    return response.data; // Xử lý phản hồi từ backend
  } catch (error) {
    console.error('Google login error:', error);
    return { success: false, message: 'Đăng nhập thất bại' };
  }
};



    