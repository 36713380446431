import { Card, CardHeader, CardContent, CardTitle } from '../../components/ui/card';
import { MyChart } from './MyChart';
import { useState } from 'react';

export function Dash() {
  const [year, setYear] = useState(2024); // Mặc định là năm 2024

  return (
    <Card>
      <CardHeader>
        <CardTitle>Doanh thu theo năm</CardTitle>
      </CardHeader>
      <CardContent>
        {/* Form nhập năm */}
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="year">Nhập năm: </label>
          <input
            type="number"
            id="year"
            value={year}
            onChange={(e) => setYear(Number(e.target.value))} // Cập nhật năm khi thay đổi
            style={{ marginLeft: '10px', marginRight: '20px' }}
          />
        </div>

        {/* Truyền giá trị năm vào component MyChart */}
        <MyChart year={year} />
      </CardContent>
    </Card>
  );
}
