import React, { useEffect, useState } from 'react';
import { fetchBookings, updateBookingStatus } from '../../services/Admin/ServicesService';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SliderBar';
import UserInfo from '../../components/UserInfoAdmin';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
const ServiceBooking = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const navigate = useNavigate();

  const STATUS_TRANSLATION = {
    pending: 'Đang chờ',
    confirmed: 'Đã xác nhận',
    canceled: 'Đã hủy',
    success: 'Hoàn thành',
  };

  // Fetch bookings from API
  const getBookings = async () => {
    setLoading(true);
    try {
      const { data, last_page } = await fetchBookings(search, page, perPage, fromDate, toDate);
      setBookings(data);
      setTotalPages(last_page);
    } catch (error) {
      toast.error('Lỗi khi tải dữ liệu!');
    } finally {
      setLoading(false);
    }
  };

  // Call API when dependencies change
  useEffect(() => {
    getBookings();
  }, [search, page, perPage, fromDate, toDate]);

  // Handle search form submission
  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    getBookings();
  };

  // Handle status change
  const handleStatusChange = async (id, newStatus) => {
    const confirmChange = window.confirm('Bạn có chắc muốn thay đổi trạng thái không?');
    if (!confirmChange) return;

    try {
      await updateBookingStatus(id, newStatus);
      toast.success('Cập nhật trạng thái thành công!');
      getBookings();
    } catch (error) {
      toast.error('Cập nhật trạng thái thất bại!');
    }
  };

  // Loading state
  if (loading)
    return (
      <div className="loading-container">
        <svg viewBox="25 25 50 50" className="spinner">
          <circle r="20" cy="50" cx="50"></circle>
        </svg>
      </div>
    );

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="content flex-grow-1">
        <div className="header">
          <h4 className="main-title-adminindex">Danh sách lịch dịch vụ</h4>
          <div className="d-flex align-items-center">
            <div className="search-bar">
              <input
                placeholder="Tìm kiếm"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              <button onClick={handleSearch}>
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <UserInfo />
        </div>

        <div className="table-container">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>STT</th>
                <th>Khách Hàng</th>
                <th>Dịch Vụ</th>
                <th>Ngày Đặt</th>
                <th>Thời Gian</th>
                <th>Trạng Thái</th>
                <th>Hành Động</th>
              </tr>
            </thead>
            <tbody>
              {bookings.length > 0 ? (
                bookings.map((booking, index) => (
                  <tr key={booking.id}>
                    <td>{index + 1}</td>
                    <td>{booking.user_name}</td>
                    <td>{booking.service_name}</td>
                    <td>{booking.booking_date}</td>
                    <td>{booking.booking_time}</td>
                    <td>
                      <select
                        className="form-select"
                        value={booking.status}
                        onChange={(e) => handleStatusChange(booking.id, e.target.value)}
                      >
                        {Object.keys(STATUS_TRANSLATION).map((status) => (
                          <option
                            key={status}
                            value={status}
                            disabled={
                              // Disable invalid status changes based on current status
                              (booking.status === 'confirmed' && status === 'pending') || // Can't go back to "Pending" if already confirmed
                              (booking.status === 'success' && status !== 'success') ||  // Can't change "Completed" to any other status
                              (booking.status === 'canceled' && status !== 'canceled')   // Can't change "Canceled" to any other status
                            }
                          >
                            {STATUS_TRANSLATION[status]}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>


                      <Link to={`/edit-servicebooking/?id=${booking.id}`} onClick={() => console.log(`Editing service ${booking.id}`)}>
                        <i className="fas fa-edit text-success" />
                      </Link>

                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">Không có đơn hàng nào</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button
                  className="page-link"
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}
                >
                  Trước
                </button>
              </li>
              <li className="page-item">
                <span className="page-link">
                  Trang {page} trên {totalPages}
                </span>
              </li>
              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <button
                  className="page-link"
                  onClick={() => setPage(page + 1)}
                  disabled={page === totalPages}
                >
                  Sau
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default ServiceBooking;
