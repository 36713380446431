import axios from 'axios';

// Định nghĩa URL cơ sở
const API_BASE_URL = 'https://api.pethaven.website/admin'; // Thay đổi URL này cho phù hợp

// Lấy thông tin Dashboard

// Lấy danh sách sản phẩm
export const fetchNews = async (search = '', page = 1, perPage = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/news`, {
            params: { search, page, perPage },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};
// Lấy danh sách danh mục
export const fetchCategoriesNew = async (search = '', page = 1, perPage = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/categoryNew`, {
            params: { search, page, perPage },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};
export const fetchPets = async (search = '', page = 1, perPage = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/pet`, {
            params: { search, page, perPage },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};
export const deleteNews = async (PetId) => {
    const response = await axios.delete(`${API_BASE_URL}/news/delete/${PetId}`);
    return response.data; // Bạn có thể trả về dữ liệu phản hồi nếu cần
};
// Lấy danh sách người dùng
export const deletePet = async (PetId) => {
    const response = await axios.delete(`${API_BASE_URL}/pet/delete/${PetId}`);
    return response.data; // Bạn có thể trả về dữ liệu phản hồi nếu cần
};
export const deleteCategory = async (categoryNewId) => {
    const response = await axios.delete(`${API_BASE_URL}/categoryNew/delete/${categoryNewId}`);
    return response.data; // Bạn có thể trả về dữ liệu phản hồi nếu cần
};