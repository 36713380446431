import axios from 'axios';
const API_BASE_URL = 'https://api.pethaven.website/admin'; // Thay đổi URL này cho phù hợp
export const fetchUsers = async (search = '', page = 1, perPage = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/user`, {
            params: { search, page, perPage },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};